import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from '../../../../Components/footer';
import LazyLoader from '../../../../Components/lazyLoader';
import './dailyRoutine.scss';
import Arasuri from '../../../../Components/arasuri';
import Error404 from '../../../../Pages/error404';
const DailyRoutine = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });

  //-----page-close-404-error-logic-p-----
  // if (true) {
  //   return <Error404 />;
  // }
  // //-----page-close-404-error-logic-p-----
  return (
    <>
      <Helmet>
        <title>શ્રી પ૧ શકિતપીઠ પરિક્રમા મહોત્સવ ૨૦૨૪</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Get detailed information of 51 Shaktipith Mahotsav."
        />
        <meta name="keywords" content="51 Shaktipith Mahotsav Ambaji" />
        <meta name="author" content="51 Shaktipith Mahotsav" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      {!isLoading ? (
        <div className="main-wrapper">
          <section className="header-banner shaktipith-banner">
            <div className="container">
              <h1>શ્રી પ૧ શકિતપીઠ પરિક્રમા મહોત્સવ ૨૦૨૪</h1>
            </div>
          </section>

          <section className="about-page">
            <div className="container">
              <div className="about-main">
                <div className="templetop">
                  <div className="row">
                    <div className="table-responsive-box">
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td colSpan="2" align="center">
                              <h3>
                                શ્રી પ૧ શકિતપીઠ પરિક્રમા દૈનિક મહોત્સવ અનુસૂચિ
                              </h3>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" align="center">
                              <h4>
                                તા.૧ર/ર/ર૦ર૪ થી તા.૧૬/ર/ર૦ર૪ સુધીના કાર્યક્રમો
                                નીચે મુજબ યોજાશે.
                              </h4>
                            </td>
                          </tr>
                          <tr>
                            <td rowSpan="9" align="center">
                              <strong>
                                ૧ર/ર/ર૦ર૪
                                <br />
                                (સોમવાર)
                              </strong>
                            </td>
                            <td>મૂર્તિઓની પ્રક્ષાાલન વિધિ</td>
                          </tr>
                          <tr>
                            <td>શોભા યાત્રા</td>
                          </tr>
                          <tr>
                            <td>ચામર યાત્રા</td>
                          </tr>
                          <tr>
                            <td>શકિતપીઠના સંકુલોમાં શકિત યાગ(યજ્ઞ)</td>
                          </tr>
                          <tr>
                            <td>ભજન મંડળીઓ ધ્વારા ભજન સત્સંગ</td>
                          </tr>
                          <tr>
                            <td>આનંદ ગરબા પાલખી પરિક્રમા યાત્રા</td>
                          </tr>
                          <tr>
                            <td>વિવિધ મંડળો ધ્વારા પરિક્રમા યાત્રા</td>
                          </tr>
                          <tr>
                            <td>ગબ્બર તળેટી ખાતે સાંસ્કૃતિક કાર્યક્રમ</td>
                          </tr>
                          <tr>
                            <td>લાઈટ એન્ડ સાઉન્ડ શો</td>
                          </tr>
                          <tr>
                            <td rowSpan="9" align="center">
                              <strong>
                                ૧૩/ર/ર૦ર૪
                                <br />
                                (મંગળવાર)
                              </strong>
                            </td>
                            <td>શકિતપીઠના મંદિર ખાતે ધજા અર્પણ કાર્યક્રમ</td>
                          </tr>
                          <tr>
                            <td>આનંદ ગરબા અખંડ ધુન પૂર્ણાહુતિ</td>
                          </tr>
                          <tr>
                            <td>માતાજીની પાદુકા યાત્રા</td>
                          </tr>
                          <tr>
                            <td>શકિતપીઠના સંકુલોમાં શકિત યાગ(યજ્ઞ)</td>
                          </tr>
                          <tr>
                            <td>ભજન મંડળીઓ ધ્વારા ભજન સત્સંગ</td>
                          </tr>
                          <tr>
                            <td>વિવિધ મંડળો ધ્વારા પરિક્રમા યાત્રા</td>
                          </tr>
                          <tr>
                            <td>ગબ્બર તળેટી ખાતે સાંસ્કૃતિક કાર્યક્રમ</td>
                          </tr>
                          <tr>
                            <td>લાઈટ એન્ડ સાઉન્ડ શો</td>
                          </tr>
                          <tr>
                            <td>પાલખી યાત્રા</td>
                          </tr>
                          <tr>
                            <td rowSpan="8" align="center">
                              <strong>
                                ૧૪/ર/ર૦૨૪
                                <br />
                                (બુધવાર)
                              </strong>
                            </td>
                            <td>જયોત યાત્રા</td>
                          </tr>
                          <tr>
                            <td>પાલખી યાત્રા</td>
                          </tr>
                          <tr>
                            <td>શકિતપીઠના સંકુલોમાં શકિત યાગ(યજ્ઞ)</td>
                          </tr>
                          <tr>
                            <td>ભજન મંડળીઓ ધ્વારા ભજન સત્સંગ</td>
                          </tr>
                          <tr>
                            <td>ગબ્બર ટોચ ખાતે રાત્રે ૧ર.૦૦ કલાકે મહાઆરતી</td>
                          </tr>
                          <tr>
                            <td>વિવિધ મંડળો ધ્વારા પરિક્રમા યાત્રા</td>
                          </tr>
                          <tr>
                            <td>ગબ્બર તળેટી ખાતે સાંસ્કૃતિક કાર્યક્રમ</td>
                          </tr>
                          <tr>
                            <td>લાઈટ એન્ડ સાઉન્ડ શો</td>
                          </tr>
                          <tr>
                            <td rowSpan="6" align="center">
                              <strong>
                                ૧પ/ર/ર૦ર૪
                                <br />
                                (ગુરુવાર)
                              </strong>
                            </td>
                            <td>શકિતપીઠના સંકુલોમાં શકિત યાગ(યજ્ઞ)</td>
                          </tr>
                          <tr>
                            <td>પાલખી યાત્રા</td>
                          </tr>
                          <tr>
                            <td>ભજન મંડળીઓ ધ્વારા ભજન સત્સંગ</td>
                          </tr>
                          <tr>
                            <td>વિવિધ મંડળો ધ્વારા પરિક્રમા યાત્રા</td>
                          </tr>
                          <tr>
                            <td>ગબ્બર તળેટી ખાતે સાંસ્કૃતિક કાર્યક્રમ</td>
                          </tr>
                          <tr>
                            <td>લાઈટ એન્ડ સાઉન્ડ શો</td>
                          </tr>
                          <tr>
                            <td rowSpan="7" align="center">
                              <strong>
                                ૧૬/ર/ર૦ર૪
                                <br />
                                (શુક્રવાર)
                              </strong>
                            </td>
                            <td>શકિતપીઠના સંકુલોમાં શકિત યાગ(યજ્ઞ)</td>
                          </tr>
                          <tr>
                            <td>પાલખી યાત્રા</td>
                          </tr>
                          <tr>
                            <td>ભજન મંડળીઓ ધ્વારા ભજન સત્સંગ</td>
                          </tr>
                          <tr>
                            <td>
                              પરિક્રમા ઉત્સવના દાતાશ્રીઓ, યજ્ઞના યજમાનશ્રીઓ,
                              બ્રાહ્મણો વિગેરેનો સન્માન કાર્યક્રમ
                            </td>
                          </tr>
                          <tr>
                            <td>વિવિધ મંડળો ધ્વારા પરિક્રમા યાત્રા</td>
                          </tr>
                          <tr>
                            <td>ગબ્બર તળેટી ખાતે સાંસ્કૃતિક કાર્યક્રમ</td>
                          </tr>
                          <tr>
                            <td>લાઈટ એન્ડ સાઉન્ડ શો</td>
                          </tr>
                          <tr>
                            <td colSpan="2">
                              <strong>સૂચનાઓ :</strong>
                              <p></p>
                              <p>
                                • રોજ સાંજે ૭.00 કલાકે ગબ્બર ટોચ તેમજ પરિક્રમાના
                                દરેક મંદિરોમાં એક સાથે ભવ્ય મહાઆરતી
                                <br />• દરરોજ સાંજે નામાંકિત કલાકારો ધ્વારા
                                સાંસ્કૃતિ કાર્યક્રમોની પ્રસ્તુતિ કરવામાં આવશે.
                                <br />• દરરોજ વિના મૂલ્યે ભોજન પ્રસાદ
                                <br />• અન્ય રાજયોમાં વસતા શકિતપીઠોના શ્રધ્ધાળુઓ
                                ધ્વારા પરિક્રમા
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default DailyRoutine;
