import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import Footer from '../../../../../Components/footer';
import Arasuri from '../../../../../Components/arasuri';
import './yagnaBookingReceipt.scss';
import { useReactToPrint } from 'react-to-print';
import ArticleDonationService from '../../../../../Api_config/Donations/articleDonation';
import { formatDate } from '../../../../Donation/Converter/date';
import SignatureService from '../../../../../Api_config/Home_page/signature';
import { routes } from '../../../../../_Constants/routes';
import ShaktipithYagnaBookingService from '../../../../../Api_config/51_shaktipith_yagna-booking/51ShaktipithYagnaBooking';

const YagnaBookingReceipt = () => {
  const navigate = useNavigate();
  const componentRef = useRef();
  const location = useLocation();
  const [transactionData, setTransactionData] = useState();
  const formattedDate = formatDate(transactionData?.createdAt);
  const [signatureData, setSignatureData] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Ambaji Temple 51 shaktipith yagna booking Receipt',
    onAfterPrint: () => {
      const styles = document.querySelectorAll("style[media='print']");
      styles.forEach((style) => style.remove());
    }
  });

  useEffect(() => {
    const id = location?.pathname?.split('/').pop();
    // console.log(location, Number(id))
    ShaktipithYagnaBookingService.getBookingById(Number(id))
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data[0]);
          setTransactionData(res.data.data[0]);
        }
      })
      .catch((e) => {
        navigate(routes?.error500);
        //  console.log(e)
      });

    SignatureService.getSignatureAll().then((res) => {
      // console.log(res.data);
      setSignatureData(res.data.data);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>
          51 Shaktipeeth Mahotsav Yagna Pooja Booking - Ambaji Temple, Gujarat
        </title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Book 51 Shaktipeeth Mahotsav for the Yagna Pooja at Ambaji Temple during the 51 Shaktipeeth Mahotsav. Participate in this divine event at Ambaji Temple and Gabbar Hill, Gujarat. Reserve your online booking for an unforgettable spiritual experience. Only One Slot Per Devotee is Available Per Day."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, 51 Shaktipeeth Mahotsav, Yagna Pooja Booking, Shaktipeeth Event, Gabbar Hill Pooja, Ambaji Yagna, Gujarat Shaktipeeth, Online Yagna Booking, Ambaji Temple Events, Spiritual Yagna Gujarat, Shaktipeeth Festival, Temple Pooja Booking, Ambaji Temple Events Registration"
        />
        <meta
          name="author"
          content="Ambaji Temple, Banaskatha Gujarat | BinTech Services"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="booking" style={{ backgroundColor: '#f5f5f5' }}>
        <section className="header-banner temple-banner">
          <div className="container">
            <div className="header-heading">
              <h3>51 Shaktipeeth Mahotsav-2025</h3>
              <h3
                style={{
                  marginBottom: '0'
                }}
              >
                Yagna Booking Receipt
              </h3>
            </div>
          </div>
        </section>
        <div className="print-container" ref={componentRef}>
          <div className="pdf-btn">
            <div className="container">
              <div className="row">
                <div
                  style={{ width: '800px', margin: '20px auto 5px' }}
                  className="email-container"
                >
                  <table width="100%">
                    <tbody>
                      <tr className="header">
                        <td
                          width="17%"
                          rowSpan="3"
                          align="center"
                          valign="middle"
                        >
                          <div className="logo">
                            <img
                              src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201772258_HeaderEnglish.png"
                              style={{ width: '100px', height: '100px' }}
                              alt="S1 shaktipeeth Yagna Booking - Ambaji Temple Banaskatha Gujarat"
                            />
                          </div>
                        </td>
                        <td width="83%">
                          <h1>
                            Shree Arasuri Ambaji Mata Devasthan Trust, Ambaji
                          </h1>
                        </td>
                      </tr>
                      <tr className="header">
                        <td>
                          <p>
                            <strong>Ta.</strong> Danta, &nbsp;
                            <strong>Dist.</strong> Banaskantha, &nbsp; Gujarat -
                            385110.
                          </p>
                          <p>
                            <strong>Phone No</strong> : 02749-262236 &nbsp;
                            <strong>Email </strong>:
                            ambajitempletrust@gmail.com, &nbsp;
                            www.ambajitemple.in
                          </p>
                          <p>
                            <strong>PAN No </strong>: AACTS5457M &nbsp;
                            <strong>GST No</strong> : 24AACTS5457M2Z0
                          </p>
                        </td>
                      </tr>
                      <tr className="header"></tr>
                      <tr className="headig-text">
                        <td colSpan="2" align="center" valign="middle">
                          <h2>
                            51 Shaktipeeth Mahotsav 2025
                            <br />
                            Yagna Booking Receipt
                          </h2>
                        </td>
                      </tr>
                      <tr className="rs-note">
                        <td colSpan="2" align="center">
                          <p>
                            "May the holy mother Maa Ambe Bless you and your
                            family, friends and give you a happy,
                            <br /> prosperous and spiritually enlightened life
                            ahead"
                          </p>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="2" align="center">
                          <table width="100%" className="date-section">
                            <tbody>
                              <tr>
                                <td width="50%">
                                  <strong>
                                    Payment Transaction Ref Number
                                  </strong>
                                </td>
                                <td width="50%">
                                  {transactionData?.YagnaPaymentDetails[0]
                                    ?.receipt_number ||
                                    transactionData?.YagnaPaymentDetails[0]
                                      ?.mihpayid ||
                                    '-'}
                                </td>
                              </tr>
                              <tr>
                                <td width="50%">
                                  <strong>Payment Receipt Number</strong>
                                </td>
                                <td width="50%">
                                  {transactionData?.booking_number || '-'}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Yagna Temple</strong>
                                </td>
                                <td>
                                  {' '}
                                  {transactionData?.YagnaTemple?.name || '-'} ,
                                  {transactionData?.YagnaTemple?.description ||
                                    '-'}
                                  ,
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Yagna Temple Location</strong>
                                </td>
                                <td>
                                  {' '}
                                  {transactionData?.YagnaTemple?.location ||
                                    '-'}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Yagna Amount</strong>
                                </td>
                                <td>
                                  Rs.{' '}
                                  {transactionData?.YagnaTemple?.YagnaCategory
                                    ?.yagna_amount || '-'}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Yagna Date</strong>
                                </td>
                                <td> {transactionData?.yagna_date || '-'}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Payment Date</strong>
                                </td>
                                <td>{formattedDate}</td>
                              </tr>

                              <tr>
                                <td width="50%">
                                  <strong>Yajman Name</strong>
                                </td>
                                <td width="50%">
                                  {transactionData?.first_name ||
                                  transactionData?.last_name
                                    ? `${transactionData?.first_name || ''} ${
                                        transactionData?.last_name || ''
                                      }`
                                    : '-'}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <strong>Mode of Payment</strong>
                                </td>
                                <td> {transactionData?.payment_mode || '-'}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <br />
                      {/* Add Terms and Conditions Section */}
                      <tr>
                        <td colSpan="2">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="terms-text">
                                <h6>
                                  <strong>
                                    Yagna Booking Terms and Conditions:
                                  </strong>
                                </h6>
                                <ol style={{ textAlign: 'justify' }}>
                                  <li>
                                    The yajman (participant) must attend the
                                    yagna at the location and on the date they
                                    have registered for. Changes in location or
                                    date will not be allowed.
                                  </li>

                                  <li>
                                    The temple trust will arrange all provisions
                                    for the yagna, including the yagna kund,
                                    pooja materials, dakshina and fruit meals
                                    for up to 4 individuals per yagna.
                                  </li>

                                  <li>
                                    On the day of the yagna, the yajman is
                                    requested to arrive at the selected
                                    location/complex near Gabbar between 9:00 AM
                                    to 9:30 AM.
                                  </li>

                                  <li>
                                    Fruit meals will be provided by the temple
                                    trust for a maximum of 4 to 5 people per
                                    yagna (per yajman).
                                  </li>

                                  <li>
                                    Once the booking is confirmed, no changes to
                                    the date or location of the yagna will be
                                    entertained.
                                  </li>

                                  <li>
                                    Once the yagna registration is completed, it
                                    cannot be canceled under any circumstances,
                                    and the amount paid will not be refunded.
                                  </li>

                                  <li>
                                    If the yajman wishes, they may nominate
                                    another person to participate in the
                                    registered yagna on their behalf.
                                  </li>

                                  <li>
                                    For any inquiries related to the yagna,
                                    please contact 99745 46100 or 87996 00890.
                                  </li>
                                </ol>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr align="center" valign="middle" className="teg-line">
                        <td colSpan="2">
                          <table
                            cellSpacing="0"
                            cellPadding="0"
                            border="0"
                            width="100%"
                          >
                            <tbody>
                              <tr align="center">
                                <td
                                  width="40%"
                                  valign="middle"
                                  className="barcode"
                                >
                                  <img
                                    src="https://qrcg-free-editor.qr-code-generator.com/main/assets/images/websiteQRCode_noFrame.png"
                                    width="110"
                                    alt="Article Donation - Ambaji Temple Banaskatha Gujarat"
                                  />
                                </td>
                                <td
                                  width="60%"
                                  valign="middle"
                                  className="sign"
                                >
                                  {signatureData?.map((item) => {
                                    return (
                                      <img
                                        alt="Article Donation - Ambaji Temple Banaskatha Gujarat"
                                        src={item.signature_image}
                                        // src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716962038848_MicrosoftTeams-image(7).png"
                                        width="120"
                                        height="100"
                                      />
                                    );
                                  })}
                                  <p>
                                    {signatureData?.length >= 1 &&
                                      signatureData[0]?.name}
                                    <br />
                                    {signatureData?.length >= 1 &&
                                      signatureData[0]?.designation}
                                    <br />
                                    Shree Arasuri Ambaji Mata Devasthan Trust
                                    Ambaji
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center">
          <button className="btn orangebtn my-2" onClick={handlePrint}>
            Download Receipt
          </button>
        </div>
      </div>
      <Arasuri />
      <Footer />
    </>
  );
};

export default YagnaBookingReceipt;
