import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
// import './payment-status.css';
import GoldDonationService from '../../../../../Api_config/Donations/goldDonation';
import UtilsService from '../../../../../Api_config/Home_page/utils';
import Arasuri from '../../../../../Components/arasuri';
import Footer from '../../../../../Components/footer';
import { routes } from '../../../../../_Constants/routes';
import ShaktipithYagnaBookingService from '../../../../../Api_config/51_shaktipith_yagna-booking/51ShaktipithYagnaBooking';
import { useParams } from 'react-router-dom';

const BookingConfirmation = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (id) {
      console.log(id);

      ShaktipithYagnaBookingService.getBookingById(id)
        .then((response) => {
          console.log('API Response:', response); // Log the full response
          if (response?.data?.success && response.data.data.length > 0) {
            setTransactionData(response.data.data[0]);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error('Error fetching booking data:', error);
          setError('Error fetching booking data');
        });
    } else {
      setError('No ID provided');
    }
  }, [id]);

  const printPaymentReciept = () => {
    const id = location?.pathname?.split('/').pop();
    navigate(`/51-shaktipeeth-yagna-booking-receipt/${id}`, {
      state: { data: transactionData }
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <section className="header-banner yagna-booking">
        <div className="container">
          <div className="header-heading">
            <h3>51 Shaktipeeth Mahotsav-2025</h3>
            <h3>09/02/2025 - 11/02/2025</h3>
            <h3
              style={{
                marginBottom: '0'
              }}
            >
              Yagna Booking Successful
            </h3>
          </div>
        </div>
      </section>
      <section className="about-page">
        <div className="container">
          <div className="money-donation-success">
            <div className="check-icon">
              <FaCheck />
            </div>
            <br />
            <h3 className="text-center">Payment Successful</h3>
            <br />
            <div className="row" style={{ rowGap: '15px' }}>
              <div className="col-5">
                <b>Full Name :</b>
              </div>
              <div className="col-7">
                {transactionData?.first_name || transactionData?.last_name
                  ? `${transactionData?.first_name || ''} ${
                      transactionData?.last_name || ''
                    }`
                  : '-'}
              </div>
              <div className="col-5">
                <b>Mobile Number :</b>
              </div>
              <div className="col-7">
                {transactionData?.primary_contact_number || '-'}
              </div>
              <div className="col-5">
                <b>Email Id :</b>
              </div>
              <div className="col-7">{transactionData?.email_id || '-'}</div>
              {/* <div className="col-5">
                <b>Adhar Number :</b>
              </div> */}
              {/* <div className="col-7">
                {transactionData?.aadhar_number || '-'}
              </div> */}
              <div className="col-5">
                <b>Address :</b>
              </div>
              <div className="col-7">{transactionData?.address || '-'}</div>
              <div className="col-5">
                <b>Mode of Payment :</b>
              </div>
              <div className="col-7">
                {transactionData?.payment_mode || '-'}
              </div>
              <div className="col-5">
                <b>Date of yagna : </b>
              </div>
              <div className="col-7">
                {transactionData?.yagna_date || '-'}

                {/* {UtilsService.formatDate(transactionData?.yagna_date) || '-'} */}
              </div>
              <div className="col-5">
                <b>Yagna Booked : </b>
              </div>
              <div className="col-7">
                {transactionData?.YagnaTemple?.name || '-'} ,
                {transactionData?.YagnaTemple?.description || '-'},
              </div>
              <div className="col-5">
                <b>Amount :</b>
              </div>
              <div className="col-7">
                Rs.{' '}
                {transactionData?.YagnaTemple?.YagnaCategory?.yagna_amount ||
                  '-'}
              </div>
            </div>

            <div className="d-flex justify-content-end mt-4">
              <button
                className="btn btn-success"
                onClick={() => printPaymentReciept()}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </section>
      <Arasuri />
      <Footer />
    </>
  );
};

export default BookingConfirmation;

// import { useEffect, useState } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { FaCheck } from 'react-icons/fa';
// // import './payment-status.css';
// import GoldDonationService from '../../../../../Api_config/Donations/goldDonation';
// import UtilsService from '../../../../../Api_config/Home_page/utils';
// import Arasuri from '../../../../../Components/arasuri';
// import Footer from '../../../../../Components/footer';
// import { routes } from '../../../../../_Constants/routes';
// import ShaktipithYagnaBookingService from '../../../../../Api_config/51_shaktipith_yagna-booking/51ShaktipithYagnaBooking';
// import { useParams } from 'react-router-dom';

// const BookingConfirmation = () => {
//   const { id } = useParams();
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [transactionData, setTransactionData] = useState();

//   useEffect(() => {
//     if (id) {
//       // Fetch booking data based on the id
//       ShaktipithYagnaBookingService.getBookingById(id)
//         .then((response) => {
//           if (response.data.success) {
//             setTransactionData(response.data.data);
//           }
//         })
//         .catch((error) => {
//           console.error('Error fetching booking data:', error);
//         });
//     }
//   }, [id]);

//   const printPaymentReciept = () => {
//     const id = location?.pathname?.split('/').pop();
//     navigate(`/51shaktipith-yagna-booking-receipt/${id}`, {
//       state: { data: transactionData }
//     });
//   };

//   return (
//     <>
//       <section className="header-banner yagna-booking">
//         <div className="container">
//           <h1>
//             51 Shaktipeeth Mahotsav 2025
//             <br />
//             07/02/2025 - 11/02/2025
//             {/* શ્રી ૫૧ શક્તિપીઠ પરિક્રમા મહોત્સવ ૨૦૨પ <br />
//                 યજ્ઞ બુકિંગ (૦૭/૦૨/૨૦૨પ થી ૧૧/૦૨/૨૦૨પ) */}
//           </h1>
//         </div>
//       </section>
//       <section className="about-page">
//         <div className="container">
//           <div className="money-donation-success">
//             <div className="check-icon">
//               <FaCheck />
//             </div>
//             <br />
//             <h3 className="text-center">Payment Successfull</h3>
//             <br />
//             <div className="row" style={{ rowGap: '15px' }}>
//               <>
//                 <div className="col-5">
//                   <b>Full Name :</b>
//                 </div>
//                 <div className="col-7">
//                   {transactionData?.first_name || transactionData?.last_name
//                     ? `${transactionData?.first_name || ''} ${
//                         transactionData?.last_name || ''
//                       }`
//                     : '-'}
//                 </div>
//                 <div className="col-5">
//                   <b>Mobile Number :</b>
//                 </div>
//                 <div className="col-7">
//                   {transactionData?.primary_contact_number}
//                 </div>

//                 <div className="col-5">
//                   <b>Email Id :</b>
//                 </div>
//                 <div className="col-7">{transactionData?.email_id}</div>

//                 <div className="col-5">
//                   <b>Adhar Number :</b>
//                 </div>
//                 <div className="col-7">{transactionData?.aadhar_number}</div>

//                 <div className="col-5">
//                   <b>Address :</b>
//                 </div>
//                 <div className="col-7">{transactionData?.address}</div>

//                 <div className="col-5">
//                   <b>Mode of Payment :</b>
//                 </div>
//                 <div className="col-7">{transactionData?.payment_mode}</div>
//               </>

//               <div className="col-5">
//                 <b>Date of yagna : </b>
//               </div>
//               <div className="col-7">
//                 {UtilsService.formatDate(transactionData?.createdAt)}
//               </div>

//               <div className="col-5">
//                 <b>Yagna Booked : </b>
//               </div>
//               <div className="col-7">{transactionData?.YagnaTemple?.name}</div>
//               <div className="col-5">
//                 <b>Amount :</b>
//               </div>
//               <div className="col-7">
//                 Rs. {transactionData?.YagnaTemple?.YagnaCategory?.yagna_amount}
//               </div>
//             </div>

//             <div className="d-flex justify-content-end mt-4">
//               <button
//                 className="btn btn-success"
//                 onClick={() => printPaymentReciept()}
//               >
//                 Download
//               </button>
//             </div>
//           </div>
//         </div>
//       </section>
//       <Arasuri />
//       <Footer />
//     </>
//   );
// };
// export default BookingConfirmation;
