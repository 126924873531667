// fundamentals
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

// components
import './termsAndConditions.scss';
import Footer from '../../../../../Components/footer';
// import '../Home/home.scss';

// Images

import Arasuri from '../../../../../Components/arasuri';
import LazyLoader from '../../../../../Components/lazyLoader';

function YagnaTermsAndConditions() {
  const [isLoading, setLoading] = useState(true);
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });

  const handleCheckboxChange = (event) => {
    setAccepted(event.target.checked);
    localStorage.setItem('accepted', event.target.checked);
  };

  const handleNextButtonClick = () => {
    if (accepted) {
      window.location.href = '51-shaktipeeth-yagna-booking';
    } else {
      alert('You must accept the terms and conditions.');
    }
  };

  return (
    <>
      <Helmet>
        <title>
          51 Shaktipeeth Mahotsav Yagna Pooja Booking - Ambaji Temple, Gujarat
        </title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Book 51 Shaktipeeth Mahotsav for the Yagna Pooja at Ambaji Temple during the 51 Shaktipeeth Mahotsav. Participate in this divine event at Ambaji Temple and Gabbar Hill, Gujarat. Reserve your online booking for an unforgettable spiritual experience. Only One Slot Per Devotee is Available Per Day."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, 51 Shaktipeeth Mahotsav, Yagna Pooja Booking, Shaktipeeth Event, Gabbar Hill Pooja, Ambaji Yagna, Gujarat Shaktipeeth, Online Yagna Booking, Ambaji Temple Events, Spiritual Yagna Gujarat, Shaktipeeth Festival, Temple Pooja Booking, Ambaji Temple Events Registration"
        />
        <meta
          name="author"
          content="Ambaji Temple, Banaskatha Gujarat | BinTech Services"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      {!isLoading ? (
        <div className="main-wrapper">
          {/* banner  */}
          {/* <section className="header-banner temple-banner">
            <div className="container">
              <h1>નિયમો અને શરતો</h1>
            </div>
          </section> */}
          <section className="header-banner yagna-booking">
            <div className="container">
              <div className="header-heading">
                <h3>51 Shaktipeeth Mahotsav-2025</h3>
                <h3>09/02/2025 - 11/02/2025</h3>
                <h3
                  style={{
                    marginBottom: '0'
                  }}
                >
                  Yagna Booking Terms and Conditions
                </h3>
              </div>
            </div>
          </section>
          {/* ----------  */}

          {/* details  */}
          <div className="terms-conditions">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="terms-text">
                    <ol>
                      <li>
                        The yajman (participant) must attend the yagna at the
                        location and on the date they have registered for.
                        Changes in location or date will not be allowed.
                      </li>
                      <br />
                      <li>
                        The temple trust will arrange all provisions for the
                        yagna, including the yagna kund, pooja materials,
                        dakshina, and fruit meals for up to 4 individuals per
                        yagna.
                      </li>
                      <br />
                      <li>
                        On the day of the yagna, the yajman is requested to
                        arrive at the selected location/complex near Gabbar
                        between 9:00 AM to 9:30 AM.
                      </li>
                      <br />

                      <li>
                        Fruit meals will be provided by the temple trust for a
                        maximum of 4 to 5 people per yagna (per yajman).
                      </li>
                      <br />
                      <li>
                        Once the booking is confirmed, no changes to the date or
                        location of the yagna will be entertained.
                      </li>
                      <br />
                      <li>
                        Once the yagna registration is completed, it cannot be
                        canceled under any circumstances, and the amount paid
                        will not be refunded.
                      </li>
                      <br />
                      <li>
                        If the yajman wishes, they may nominate another person
                        to participate in the registered yagna on their behalf.
                      </li>
                      <br />
                      <li>
                        For any inquiries related to the yagna, please contact
                        99745 46100 or 87996 00890.
                      </li>
                      <br />
                    </ol>
                  </div>
                </div>
              </div>
              <div className="row">
                <label style={{ marginBottom: '25px' }}>
                  <div className="d-flex">
                    <span>
                      <input
                        className="checkbox-boxes-sevamain"
                        type="checkbox"
                        id="accept-checkbox"
                        onChange={handleCheckboxChange}
                      />
                    </span>
                    <span>
                      <b style={{ marginLeft: '5px' }}>
                        I have thoroughly read the above rules and regulations,
                        and I commit to adhering to them when registering on
                        this portal.
                      </b>
                    </span>
                  </div>
                </label>
              </div>
              <button
                className="orangebtn"
                type="button"
                style={{ width: '100%' }}
                onClick={handleNextButtonClick}
              >
                Next
              </button>
            </div>
          </div>
          <Arasuri />
          <Footer />
          {/* ----------  */}
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
}

export default YagnaTermsAndConditions;
