import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import LazyLoader from '../../../../../Components/lazyLoader';
import './yagnaBookingList.scss';
import ShaktipithYagnaBookingService from '../../../../../Api_config/51_shaktipith_yagna-booking/51ShaktipithYagnaBooking';
import { useNavigate } from 'react-router-dom';
import Footer from '../../../../../Components/footer';
import Arasuri from '../../../../../Components/arasuri';

const ShaktipithYagnaBooking = () => {
  const [isLoading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('09-02-2025'); // Default active tab
  const [cardsData, setCardsData] = useState([]); // Dynamic data for cards
  const [isFetching, setFetching] = useState(false);
  const [selectedYagna, setSelectedYagna] = useState(null); // Track the selected Yagna
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Cluster data
  const clusters = [
    { id: '09-02-2025', label: '09-02-2025' },
    { id: '10-02-2025', label: '10-02-2025' },
    { id: '11-02-2025', label: '11-02-2025' }
  ];

  useEffect(() => {
    // Fetch data for the active tab
    fetchCardsData(activeTab);
  }, [activeTab]);

  const fetchCardsData = async (yagna_date) => {
    try {
      setFetching(true);
      const response = await ShaktipithYagnaBookingService.yagnaGetAll(
        yagna_date
      );
      if (response?.data?.data) {
        setCardsData(response.data.data);
        console.log(setCardsData);
      } else {
        setCardsData([]);
      }
      setFetching(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setCardsData([]);
      setFetching(false);
    }
  };

  //   const fetchCardsData = async (yagna_date) => {
  //     try {
  //       setFetching(true);
  //       const response = await ShaktipithYagnaBookingService.yagnaGetAll(
  //         yagna_date
  //       );
  //       console.log('API Response:', response); // Log the full response

  //       if (response?.data?.data) {
  //         const availableSlots = response.data.data.filter(
  //           (card) => !card.booked
  //         );
  //         setCardsData(availableSlots);
  //       } else {
  //         setCardsData([]);
  //       }
  //       setFetching(false);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //       setCardsData([]);
  //       setFetching(false);
  //     }
  //   };
  const handleRadioChange = (card) => {
    setSelectedYagna({
      yagna_temple_id: card.id,
      name: card.name,
      description: card.description,
      amount: card.YagnaCategory.yagna_amount,
      date: activeTab
    });
    console.log(setSelectedYagna);
  };

  const handleSaveAndContinue = () => {
    console.log('Selected Yagna:', selectedYagna); // Debugging log
    if (
      !selectedYagna ||
      !selectedYagna.yagna_temple_id ||
      !selectedYagna.name ||
      !selectedYagna.description
    ) {
      alert('Please select a valid Yagna before proceeding.');
      return;
    }

    navigate('/51-shaktipeeth-yagna-booking-form', {
      state: {
        yagna: selectedYagna,
        description: selectedYagna.description,
        date: activeTab, // Optional
        // date: new Date().toISOString(), // Optional
        yagna_temple_id: selectedYagna.yagna_temple_id
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>
          51 Shaktipeeth Mahotsav Yagna Pooja Booking - Ambaji Temple, Gujarat
        </title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Book 51 Shaktipeeth Mahotsav for the Yagna Pooja at Ambaji Temple during the 51 Shaktipeeth Mahotsav. Participate in this divine event at Ambaji Temple and Gabbar Hill, Gujarat. Reserve your online booking for an unforgettable spiritual experience. Only One Slot Per Devotee is Available Per Day."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, 51 Shaktipeeth Mahotsav, Yagna Pooja Booking, Shaktipeeth Event, Gabbar Hill Pooja, Ambaji Yagna, Gujarat Shaktipeeth, Online Yagna Booking, Ambaji Temple Events, Spiritual Yagna Gujarat, Shaktipeeth Festival, Temple Pooja Booking, Ambaji Temple Events Registration"
        />
        <meta
          name="author"
          content="Ambaji Temple, Banaskatha Gujarat | BinTech Services"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      {!isLoading ? (
        <div className="booking">
          <section className="header-banner yagna-booking">
            <div className="container">
              <div className="header-heading">
                <h3>51 Shaktipeeth Mahotsav-2025</h3>
                <h3>09/02/2025 - 11/02/2025</h3>
                <h3
                  style={{
                    marginBottom: '0'
                  }}
                >
                  Yagna Booking Slots
                </h3>
              </div>
            </div>
          </section>

          <section className="about-page">
            <div className="container">
              <div className="row">
                <div className="text-center">
                  <h4 className="red-text">
                    Gabbar Hill Navchandi Yagna : INR 25,000 (Including Yagna
                    Samagri and Dakshina)
                  </h4>
                  <h4 className="red-text">
                    Parikrama Sankul Yagna : INR 11,000 (Including Yagna Samagri
                    and Dakshina)
                  </h4>
                  <h6>
                    Note: The listings below are currently available for booking
                    on the selected date. Temples not shown here are already
                    booked.
                  </h6>
                </div>
              </div>

              <div className="cluster-pricing guidelines">
                {/* Tabs */}
                <div className="tabs">
                  {clusters.map((cluster) => (
                    <div
                      key={cluster.id}
                      className={`col tab ${
                        activeTab === cluster.id ? 'active' : ''
                      }`}
                      onClick={() => handleTabClick(cluster.id)}
                    >
                      {cluster.label}
                    </div>
                  ))}
                </div>
                <br />
                {/* Tab Content */}
                <div className="tab-content">
                  {clusters.map(
                    (cluster) =>
                      activeTab === cluster.id && (
                        <div key={cluster.id} className="content">
                          {cardsData.length > 0 ? (
                            <div className="row">
                              {cardsData.map((card) => (
                                <div key={card.id} className="col-md-6 mb-4">
                                  <div
                                    className="card h-100"
                                    style={{
                                      border: '1px solid #ccc',
                                      padding: '10px',
                                      borderRadius: '10px',
                                      textAlign: 'left'
                                    }}
                                  >
                                    <div
                                      className="d-flex align-items-center mb-3"
                                      style={{
                                        margin: '10px'
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        id={`radio-${card.id}`}
                                        name="cluster"
                                        value={card.id}
                                        onChange={() => handleRadioChange(card)}
                                        style={{ marginRight: '10px' }}
                                      />
                                      <h4
                                        className="mb-0 mt-0"
                                        style={{ fontWeight: 'bold' }}
                                      >
                                        {card.name} (INR{' '}
                                        {card.YagnaCategory.yagna_amount})
                                      </h4>
                                    </div>

                                    <div style={{ marginLeft: '24px' }}>
                                      <p>
                                        Temples under the cluster:
                                        <br />
                                        {card.description ||
                                          'No description available.'}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <p>No data available for the selected date.</p>
                          )}
                        </div>
                      )
                  )}
                  <br />
                  <div
                    className="form-footer mt-2"
                    style={{ textAlign: 'left' }}
                  >
                    <button
                      type="button"
                      className="orangebtn w-30"
                      onClick={handleSaveAndContinue}
                    >
                      Save and Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default ShaktipithYagnaBooking;

// 2----
// import React, { useState, useEffect } from 'react';
// import { Helmet } from 'react-helmet-async';
// import LazyLoader from '../../../../../Components/lazyLoader';
// import './yagnaBookingList.scss';
// import ShaktipithYagnaBookingService from '../../../../../Api_config/51_shaktipith_yagna-booking/51ShaktipithYagnaBooking';
// import { useNavigate } from 'react-router-dom';

// const ShaktipithYagnaBooking = () => {
//   const [isLoading, setLoading] = useState(true);
//   const [activeTab, setActiveTab] = useState('09-02-2025'); // Default active tab
//   const [cardsData, setCardsData] = useState([]); // Dynamic data for cards
//   const [isFetching, setFetching] = useState(false);
//   const [selectedCard, setSelectedCard] = useState(null);
//   const navigate = useNavigate();
//   const [selectedYagna, setSelectedYagna] = useState(null); // Track the selected Yagna

//   useEffect(() => {
//     setTimeout(() => {
//       setLoading(false);
//     }, 5000);
//   }, []);

//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
//   };

//   // Cluster data
//   const clusters = [
//     { id: '09-02-2025', label: '09-02-2025' },
//     { id: '10-02-2025', label: '10-02-2025' },
//     { id: '11-02-2025', label: '11-02-2025' }
//   ];

//   useEffect(() => {
//     // Initial data fetch for the default active tab
//     fetchCardsData(activeTab);
//   }, [activeTab]);

//   const fetchCardsData = async (yagna_date) => {
//     try {
//       setFetching(true); // Optional loading state
//       const response = await ShaktipithYagnaBookingService.yagnaGetAll(
//         yagna_date
//       );
//       console.log('API Response:', response.data); // Debugging
//       if (response?.data?.data) {
//         setCardsData(response.data.data); // Extract the `data` array
//       } else {
//         setCardsData([]); // Fallback if `data` is not present
//       }
//       setFetching(false);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       setCardsData([]); // Reset on error
//       setFetching(false);
//     }
//   };

//   const handleSaveAndContinue = () => {
//     // Navigate to the new page
//     navigate('/51-shaktipeeth-yagna-booking-form', {
//       state: {
//         date: activeTab,
//         yagna: selectedYagna
//       }
//     });
//   };

//   return (
//     <>
//       <Helmet>
//         <title>Ambaji Yagna Booking</title>
//         <meta charset="UTF-8" />
//         <meta
//           name="description"
//           content="Shri 51 Shaktipeeth Parikrama Mahotsav Yagna Booking. Book Gabbar Yagna and Shaktipeeth Yagna at Ambaji Temple."
//         />
//         <meta
//           name="keywords"
//           content="Ambaji Yajna Booking, Gabbar Yagna Booking, Shaktipeeth Yagna Booking, Parikrama Mahotsav Yagna Booking, Shri 51 Shaktipeeth Parikrama Mahotsav Yagna Booking"
//         />
//         <meta name="author" content="BinTech Services" />
//         <meta name="viewport" content="width=device-width, initial-scale=1.0" />
//       </Helmet>

//       {!isLoading ? (
//         <div className="booking">
//           <section className="header-banner yagna-booking">
//             <div className="container">
//               <h1>
//                 51 Shaktipeeth Mahotsav 2025
//                 <br />
//                 07/02/2025 - 11/02/2025
//               </h1>
//             </div>
//           </section>

//           <section className="about-page">
//             <div className="row guidelines">
//               <h4 className="red-text">
//                 Gabbar Yagna : INR 25,000 (Including Yagna, Fal, Phool, and
//                 Bhramin Daxina)
//               </h4>
//               <h4 className="red-text">
//                 Mandir Yagna : INR 11,000 (Including Yagna, Fal, Phool, and
//                 Bhramin Daxina)
//               </h4>
//               <h6>
//                 Note: The listings below are currently available for booking on
//                 the selected date. Temples not shown here are already booked.
//               </h6>
//             </div>

//             <div className="cluster-pricing guidelines">
//               {/* Tabs */}
//               <div className="tabs row">
//                 {clusters.map((cluster) => (
//                   <div
//                     key={cluster.id}
//                     className={`col tab ${
//                       activeTab === cluster.id ? 'active' : ''
//                     }`}
//                     onClick={() => handleTabClick(cluster.id)}
//                   >
//                     {cluster.label}
//                   </div>
//                 ))}
//               </div>
//               <br />

//               {/* Tab Content */}
//               <div className="tab-content">
//                 {clusters.map(
//                   (cluster) =>
//                     activeTab === cluster.id && (
//                       <div key={cluster.id} className="content">
//                         {cardsData.length > 0 ? (
//                           <div
//                             className="card-grid"
//                             style={{
//                               display: 'grid',
//                               gridTemplateColumns: 'repeat(12, 1fr)', // 12-column grid for full control
//                               gap: '20px'
//                             }}
//                           >
//                             {cardsData.map((card, index) => (
//                               <div
//                                 key={card.id}
//                                 className="card"
//                                 style={{
//                                   gridColumn:
//                                     index === 0 ? 'span 12' : 'span 6',
//                                   border: '1px solid #ccc',
//                                   padding: '10px',
//                                   borderRadius: '20px',
//                                   textAlign: 'left',
//                                   boxSizing: 'border-box',
//                                   color: index === 0 ? '#ae0015' : 'black'
//                                 }}
//                               >
//                                 <div
//                                   style={{
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     marginBottom: '10px'
//                                   }}
//                                 >
//                                   <input
//                                     type="radio"
//                                     id={`radio-${card.id}`}
//                                     name="cluster"
//                                     value={card.id}
//                                     style={{ marginRight: '10px' }}
//                                   />
//                                   <h4 style={{ fontWeight: 'bold', margin: 0 }}>
//                                     {card.name} (INR{' '}
//                                     {card.YagnaCategory.yagna_amount})
//                                   </h4>
//                                 </div>

//                                 <div style={{ marginLeft: '24px' }}>
//                                   <p>
//                                     Temples under the cluster:
//                                     <br />
//                                     {card.description ||
//                                       'No description available.'}
//                                   </p>
//                                 </div>
//                               </div>
//                             ))}
//                           </div>
//                         ) : (
//                           <p>No data available for the selected date.</p>
//                         )}
//                       </div>
//                     )
//                 )}

//                 <br />
//                 <div className="form-footer mt-2" style={{ textAlign: 'left' }}>
//                   <button
//                     type="button"
//                     className="orangebtn w-30 "
//                     onClick={handleSaveAndContinue}
//                   >
//                     Save and Continue
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </div>
//       ) : (
//         <LazyLoader />
//       )}
//     </>
//   );
// };

// export default ShaktipithYagnaBooking;

// 1-------
// import React, { useState, useEffect } from 'react';
// import { Helmet } from 'react-helmet-async';
// import LazyLoader from '../../../../../Components/lazyLoader';
// import './yagnaBookingList.scss';
// import YagnaBookingService from '../../../../../Api_config/Yagna_booking/yagnaBooking';
// import ShaktipithYagnaBookingService from '../../../../../Api_config/51_shaktipith_yagna-booking/51ShaktipithYagnaBooking';
// import { useNavigate } from 'react-router-dom';

// const ShaktipithYagnaBooking = () => {
//   const [isLoading, setLoading] = useState(true);
//   const [activeTab, setActiveTab] = useState('09-02-2025'); // Default active tab
//   const [cardsData, setCardsData] = useState([]); // Dynamic data for cards
//   const [isFetching, setFetching] = useState(false);
//   const [selectedCard, setSelectedCard] = useState(null);
//   const navigate = useNavigate();
//   const [selectedYagna, setSelectedYagna] = useState(null); // Track the selected Yagna

//   useEffect(() => {
//     setTimeout(() => {
//       setLoading(false);
//     }, [5000]);
//   });
//   const handleRadioChange = (yagna) => {
//     console.log('Selected Yagna:', yagna); // Debugging to check which Yagna is selected
//     setSelectedYagna(yagna);
//   };

//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
//   };

//   const handleCardSelection = (cardId) => {
//     console.log('Selected Card ID:', cardId); // Debugging to check which card is selected
//     setSelectedCard(cardId); // Set selected card ID
//   };

//   // Cluster data
//   const clusters = [
//     { id: '09-02-2025', label: '09-02-2025' },
//     { id: '10-02-2025', label: '10-02-2025' },
//     { id: '11-02-2025', label: '11-02-2025' }
//   ];

//   useEffect(() => {
//     // Initial data fetch for the default active tab
//     fetchCardsData(activeTab);
//   }, [activeTab]);

//   const fetchCardsData = async (yagna_date) => {
//     try {
//       setFetching(true); // Optional loading state
//       const response = await ShaktipithYagnaBookingService.yagnaGetAll(
//         yagna_date
//       );
//       console.log('API Response:', response.data); // Debugging
//       if (response?.data?.data) {
//         setCardsData(response.data.data); // Extract the `data` array
//       } else {
//         setCardsData([]); // Fallback if `data` is not present
//       }
//       setFetching(false);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       setCardsData([]); // Reset on error
//       setFetching(false);
//     }
//   };

//   const handleSaveAndContinue = () => {
//     if (selectedYagna && selectedCard) {
//       // Assuming you want to pass the Yagna Temple ID and other details
//       const selectedCardData = cardsData.find(
//         (card) => card.id === selectedCard
//       );
//       const yagnaTempleId = selectedCardData
//         ? selectedCardData.temple_id
//         : null;

//       if (yagnaTempleId) {
//         navigate('/51-shaktipeeth-yagna-booking-form', {
//           state: {
//             date: activeTab,
//             yagna: selectedYagna,
//             yagna_temple_id: yagnaTempleId, // Passing the Yagna Temple ID
//             selected_card_id: selectedCard // Optionally pass the selected card ID
//           }
//         });
//       } else {
//         console.error('Yagna Temple ID not found!');
//       }
//     } else {
//       console.error('No Yagna or card selected!');
//     }
//   };

//   return (
//     <>
//       <Helmet>
//         <title>Ambaji Yagna Booking</title>
//         <meta charset="UTF-8" />
//         <meta
//           name="description"
//           content="Shri 51 Shaktipeeth Parikrama Mahotsav Yagna Booking. Book Gabbar Yagna and Shaktipeeth Yagna at Ambaji Temple. "
//         />
//         <meta
//           name="keywords"
//           content="Ambaji Yajna Booking , Gabbar Yagna Booking , Shaktipeeth Yagna Booking , Parikrama Mahotsav Yagna Booking, Shri 51 Shaktipeeth Parikrama Mahotsav Yagna Booking "
//         />
//         <meta name="author" content="BinTech Services" />
//         <meta name="viewport" content="width=device-width, initial-scale=1.0" />
//       </Helmet>
//       {!isLoading ? (
//         <div className="booking">
//           <section className="header-banner yagna-booking">
//             <div className="container">
//               <h1>
//                 51 Shaktipeeth Mahotsav 2025
//                 <br />
//                 07/02/2025 - 11/02/2025
//                 {/* શ્રી ૫૧ શક્તિપીઠ પરિક્રમા મહોત્સવ ૨૦૨પ <br />
//                 યજ્ઞ બુકિંગ (૦૭/૦૨/૨૦૨પ થી ૧૧/૦૨/૨૦૨પ) */}
//               </h1>
//             </div>
//           </section>
//           <section className="about-page">
//             <div className="row guidelines">
//               <h4 className="red-text">
//                 Gabbar Yagna : INR 25,000 (Including Yagna,Fal,Phool and Bhramin
//                 Daxina)
//               </h4>
//               <h4 className="red-text">
//                 Mandir Yagna : INR 11,000 (Including Yagna,Fal,Phool and Bhramin
//                 Daxina)
//               </h4>
//               <h6>
//                 Note: The listings below are currently available for booking on
//                 the selected date. Temples not shown here are already booked.
//               </h6>
//             </div>

//             <div className="cluster-pricing guidelines">
//               {/* Tabs */}
//               <div className="tabs row">
//                 {clusters.map((cluster) => (
//                   <div
//                     key={cluster.id}
//                     className={`col tab ${
//                       activeTab === cluster.id ? 'active' : ''
//                     }`}
//                     onClick={() => handleTabClick(cluster.id)}
//                   >
//                     {cluster.label}
//                   </div>
//                 ))}
//               </div>
//               <br />

//               {/* Tab Content */}
//               <div className="tab-content">
//                 {/* {clusters.map(
//                   (cluster) =>
//                     activeTab === cluster.id && (
//                       <div key={cluster.id} className="content">
//                         {cardsData.length > 0 ? (
//                           <div
//                             className="card-grid"
//                             style={{
//                               display: 'flex',
//                               flexWrap: 'wrap',
//                               gap: '20px'
//                             }}
//                           >
//                             {cardsData.map((card, index) => (
//                               <div
//                                 key={card.id}
//                                 className="card"
//                                 style={{
//                                   flex: '0 0 calc(50% - 10px)', // 50% width minus some gap
//                                   border: '1px solid #ccc',
//                                   padding: '10px',
//                                   borderRadius: '20px',
//                                   textAlign: 'left',
//                                   boxSizing: 'border-box' // Ensure padding and border are included in width
//                                 }}
//                               >
//                                 <div
//                                   style={{
//                                     display: 'flex',
//                                     marginBottom: '10px'
//                                   }}
//                                 >
//                                   <input
//                                     type="radio"
//                                     id={`radio-${card.id}`}
//                                     name="cluster"
//                                     value={card.id}
//                                     style={{ marginRight: '10px' }}
//                                     onChange={() => handleRadioChange(card)}
//                                   />
//                                   <h4 style={{ fontWeight: 'bold' }}>
//                                     {card.name} (INR{' '}
//                                     {card.YagnaCategory.yagna_amount})
//                                   </h4>
//                                 </div>
//                                 <p>
//                                   Location: <strong>{card.location}</strong>
//                                 </p>
//                                 <p>
//                                   {card.description ||
//                                     'No description available.'}
//                                 </p>
//                               </div>
//                             ))}
//                           </div>
//                         ) : (
//                           <p>No data available for the selected date.</p>
//                         )}
//                       </div>
//                     )
//                 )} */}

//                 {clusters.map(
//                   (cluster) =>
//                     activeTab === cluster.id && (
//                       <div key={cluster.id} className="content">
//                         {cardsData.length > 0 ? (
//                           <div
//                             className="card-grid"
//                             style={{
//                               display: 'grid',
//                               gridTemplateColumns: 'repeat(12, 1fr)', // Use a 12-column grid for full control
//                               gap: '20px'
//                             }}
//                           >
//                             {cardsData.map((card, index) => (
//                               <div
//                                 key={card.id}
//                                 className="card"
//                                 style={{
//                                   gridColumn:
//                                     index === 0 ? 'span 12' : 'span 6', // First card spans 12 (full row), others span 6 (2 columns)
//                                   border: '1px solid #ccc',
//                                   padding: '10px',
//                                   borderRadius: '20px',
//                                   textAlign: 'left',
//                                   boxSizing: 'border-box',
//                                   color: index === 0 ? '#ae0015' : 'black' // Red text for the first card
//                                 }}
//                               >
//                                 {/* Flex container for radio button and name */}
//                                 <div
//                                   style={{
//                                     display: 'flex',
//                                     alignItems: 'center', // Align radio and name horizontally
//                                     marginBottom: '10px' // Add some space after the radio and name
//                                   }}
//                                 >
//                                   {/* Radio Button */}
//                                   <input
//                                     type="radio"
//                                     id={`radio-${card.id}`}
//                                     name="cluster"
//                                     value={card.id}
//                                     style={{ marginRight: '10px' }} // Spacing between radio and name
//                                     // onChange={() => handleRadioChange(card.id)}
//                                     onChange={() =>
//                                       handleCardSelection(card.id)
//                                     }
//                                   />
//                                   {/* Name and Amount */}
//                                   <h4 style={{ fontWeight: 'bold', margin: 0 }}>
//                                     {card.name} (INR{' '}
//                                     {card.YagnaCategory.yagna_amount})
//                                   </h4>
//                                 </div>

//                                 {/* Description: Temples under the cluster */}
//                                 <div
//                                   style={{
//                                     marginLeft: '24px' // Align description below the name, add left margin to make it aligned
//                                   }}
//                                 >
//                                   <p>
//                                     Temples under the cluster:
//                                     <br />
//                                     {card.description ||
//                                       'No description available.'}
//                                   </p>
//                                 </div>
//                               </div>
//                             ))}
//                           </div>
//                         ) : (
//                           <p>No data available for the selected date.</p>
//                         )}
//                       </div>
//                     )
//                 )}

//                 <br />
//                 <div className="form-footer mt-2" style={{ textAlign: 'left' }}>
//                   <button
//                     type="button"
//                     className={`orangebtn w-30 ${
//                       !selectedYagna || !selectedCard ? 'disabled' : ''
//                     }`}
//                     onClick={handleSaveAndContinue}
//                     // disabled={!selectedYagna || !selectedCard}
//                   >
//                     Save and Continue
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </div>
//       ) : (
//         <LazyLoader />
//       )}
//     </>
//   );
// };

// export default ShaktipithYagnaBooking;
