import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaAngleLeft } from 'react-icons/fa';
import CartService from '../../../Api_config/E_store/cart';
import Footer from '../../../Components/footer';
import CheckoutService from '../../../Api_config/E_store/checkout';
import emptyCartBag from '../../../Assets/Images/E_store/EmptyBag.png';
import LazyLoader from '../../../Components/lazyLoader';
import IconMinus from '../../../Assets/Images/E_store/icon-minus.svg';
import IconPlus from '../../../Assets/Images/E_store/icon-plus.svg';
import '../../../Css/gallery.scss';
import './cart.scss';
import Arasuri from '../../../Components/arasuri';
import { routes } from '../../../_Constants/routes';

const Cart = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [cartData, setCartData] = useState([]);
  const [subAmount, setSubAmount] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [updateOp, setUpdateOp] = useState(false);

  const removeCart = async (id) => {
    const cart_data = await CartService.removeCartData(id);
    setUpdateOp(!updateOp);
    // setCartData(cart_data);
    // console.log(cart_data);
    // calculateSubAmount(cart_data);
    updateDeliveryCharge(cart_data);
  };

  useEffect(() => {
    // const fetchCartData = async () => {
    //   const cart_data = CartService.getCartData();
    //   setCartData(cart_data);
    //   calculateSubAmount(cart_data);

    //   if (cart_data) {
    //     let order_items = { order_items: cart_data };
    //     try {
    //       const res = await CheckoutService.calculateDeliveryCharge(
    //         order_items
    //       );
    //       if (res.data.success) {
    //         setDeliveryCharge(res.data.deliveryCharges);
    //       }
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   }
    //   setLoading(false);
    // };

    // const fetchCartData = async () => {
    //   // Get user ID from local storage or any other appropriate place
    //   const user_id = localStorage.getItem("user_id");

    //   if (!user_id) {
    //     console.error("User ID is not available");
    //     navigate(routes.login);
    //     setLoading(false);
    //     return;
    //   }

    //   try {
    //     const res = await CartService.getCartData(user_id).then(async (res) => {
    //       console.log(res);
    //       console.log(res.data);
    //       if (res.data.success) {
    //         const cart_data = res.data.data;
    //         setCartData(cart_data);
    //         console.log(cart_data);
    //         console.log(user_id);
    //         calculateSubAmount(cart_data);
    //         setData(res.data.data[0]);
    //         if (cart_data.length > 0) {
    //           let order_items = {
    //             order_items: cart_data,
    //             user_id: parseInt(localStorage.getItem("user_id")),
    //             product_category_id: 2,
    //           };
    //           try {
    //             const res = await CheckoutService.calculateDeliveryCharge(
    //               order_items
    //             );
    //             if (res.data.success) {
    //               setDeliveryCharge(res.data.deliveryCharges);
    //             }
    //           } catch (error) {
    //             console.error(error);
    //           }
    //         } else {
    //           // fetchCartData();
    //         }
    //       }
    //     });
    //   } catch (error) {
    //     console.error("Failed to fetch cart data", error);
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    const fetchCartData = async () => {
      // Introducing a delay using setTimeout
      setTimeout(async () => {
        // Get user ID from local storage or any other appropriate place
        const user_id = localStorage.getItem('user_id');

        if (!user_id) {
          console.error('User ID is not available');
          navigate(routes.login);
          setLoading(false);
          return;
        }

        try {
          const res = await CartService.getCartData(user_id);
          // console.log(res);
          // console.log(res.data);
          if (res.data.success) {
            const cart_data = res.data.data;
            setCartData(cart_data);
            // console.log(cart_data);
            // console.log(user_id);
            calculateSubAmount(cart_data);
            setData(res.data.data[0]);
            if (cart_data.length > 0) {
              let order_items = {
                order_items: cart_data,
                user_id: parseInt(localStorage.getItem('user_id')),
                product_category_id: 2
              };
              try {
                const res = await CheckoutService.calculateDeliveryCharge(
                  order_items
                );
                if (res.data.success) {
                  setDeliveryCharge(res.data.deliveryCharges);
                }
              } catch (error) {
                console.error(error);
              }
            } else {
              // fetchCartData();
            }
          }
        } catch (error) {
          console.error('Failed to fetch cart data', error);
        } finally {
          setLoading(false);
        }
      }, 2000); // 2000ms delay
    };

    fetchCartData();
  }, [setCartData, updateOp]);

  const calculateSubAmount = (cart_data) => {
    const subtotal = cart_data.reduce(
      (acc, item) => acc + item.quantity * item.ProductsVariant.sell_rate,
      0
    );
    setSubAmount(subtotal);
  };

  // const removeCart = async (id, user_id) => {
  //   try {
  //     await CartService.removeCartData(id);
  //     const updatedCartData = await CartService.getCartData(user_id); // Fetch updated cart data after removal
  //     setCartData(updatedCartData);
  //     calculateSubAmount(updatedCartData);
  //     updateDeliveryCharge(updatedCartData);
  //   } catch (error) {
  //     console.error("Error removing item from cart:", error);
  //   }
  // };
  //   const addToCart = (data) => {
  //     CartService.addToCartShopping({ ...data, quantity: 1 });
  //     const cart_data = CartService.getCartData();
  //     setCartData(cart_data);
  //     calculateSubAmount(cart_data);
  //     updateDeliveryCharge(cart_data);
  //   };

  const addToCart = async (item) => {
    setIsLoading(true); // Disable button while processing
    // console.log(item);
    // Make API call to add/update cart item
    const requestData = {
      product_id: item.product_id, // Assuming item.id is the product_id
      product_variants_id: item.product_variants_id, // Assuming item has product_variants_id
      user_id: parseInt(localStorage.getItem('user_id')), // Assuming user object contains the user_id
      quantity: 1
    };
    // console.log(requestData);
    try {
      const response = await CartService.addToCartShopping(requestData).then(
        async (res) => {
          if (res.data.success) {
            // Fetch the latest cart data from the backend
            const updatedCartData = await CartService.getCartData();
            setCartData(updatedCartData.data.data);
            calculateSubAmount(updatedCartData.data.data);
            updateDeliveryCharge(updatedCartData.data.data);
          } else {
            // console.log(response);
            console.error('Error adding item to cart:', response.message);
          }
        }
      );
    } catch (error) {
      console.error('API call failed:', error);
    } finally {
      setIsLoading(false); // Re-enable button after processing
    }
  };

  // const addToCart = (item) => {
  //   // Check if item already in cart and increment quantity if true
  //   const updatedCart = cartData.map((cartItem) =>
  //   // const updatedCart = cartData.map((cartItem) =>
  //     cartItem.id === item.id
  //       ? { ...cartItem, quantity: cartItem.quantity + 1 }
  //       : cartItem
  //   );
  //   console.log(updatedCart);

  //   // If item not in cart, add it with quantity 1
  //   const isItemInCart = cartData.some((cartItem) => cartItem.id === item.id);
  //   if (!isItemInCart) {
  //     console.log("not works");
  //     updatedCart.push({ ...item, quantity: 1 });
  //   }

  //   // Update state
  //   setCartData(updatedCart);
  //   calculateSubAmount(updatedCart);
  //   updateDeliveryCharge(updatedCart);
  // };

  //   const removeToCart1 = (data) => {
  //     CartService.addToCartShopping({ ...data, quantity: -1 });
  //     const cart_data = CartService.getCartData();
  //     setCartData(cart_data);
  //     calculateSubAmount(cart_data);
  //     updateDeliveryCharge(cart_data);
  //   };

  const removeToCart1 = async (item) => {
    // // Decrease quantity or remove item if quantity is 1
    // const updatedCart = cartData
    //   .map((cartItem) =>
    //     cartItem.id === item.id
    //       ? { ...cartItem, quantity: cartItem.quantity - 1 }
    //       : cartItem
    //   )
    //   .filter((cartItem) => cartItem.quantity > 0);

    // // Update state
    // setCartData(updatedCart);
    // calculateSubAmount(updatedCart);
    // updateDeliveryCharge(updatedCart);
    setIsLoading(true); // Disable button while processing
    // console.log(item);
    // Make API call to add/update cart item
    const requestData = {
      product_id: item.product_id, // Assuming item.id is the product_id
      product_variants_id: item.product_variants_id, // Assuming item has product_variants_id
      user_id: parseInt(localStorage.getItem('user_id')), // Assuming user object contains the user_id
      quantity: 1,
      is_decrement: true
    };
    // console.log(requestData);
    try {
      const response = await CartService.addToCartShopping(requestData).then(
        async (res) => {
          if (res.data.success) {
            // Fetch the latest cart data from the backend
            const updatedCartData = await CartService.getCartData();
            setCartData(updatedCartData.data.data);
            calculateSubAmount(updatedCartData.data.data);
            updateDeliveryCharge(updatedCartData.data.data);
          } else {
            // console.log(response);
            console.error('Error adding item to cart:', response.message);
          }
        }
      );
    } catch (error) {
      console.error('API call failed:', error);
    } finally {
      setIsLoading(false); // Re-enable button after processing
    }
  };

  const updateDeliveryCharge = async (cart_data) => {
    if (cart_data) {
      let order_items = {
        order_items: cart_data,
        user_id: parseInt(localStorage.getItem('user_id')),
        product_category_id: 2
      };
      try {
        const res = await CheckoutService.calculateDeliveryCharge(order_items);
        if (res.data.success) {
          setDeliveryCharge(res.data.deliveryCharges);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  if (loading) {
    return <LazyLoader />;
  }

  const handleProductClick = (productId) => {
    navigate(`/online-shopping-detail/${productId}`, {
      state: { id: productId }
    });
  };

  return (
    <>
      {cartData.length > 0 ? (
        <>
          <section className="header-banner shopOnline-banner">
            <div className="container">
              <h1>Cart</h1>
            </div>
          </section>
          <div className="cart">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="back-to" style={{ marginBottom: '30px' }}>
                    <a
                      className="button outlined btn-sm"
                      href="/online-ambaji-temple-shop"
                      title="Back to Shop"
                    >
                      <FaAngleLeft
                        style={{ fontSize: '30px', marginRight: '5px' }}
                      />
                      Continue Shopping
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="row">
                    {cartData.map((item, i) => (
                      // <React.Fragment>
                      <React.Fragment key={i}>
                        <hr className="border-dark" />
                        <div className="Shopping-btn">
                          <div className="row align-items-center">
                            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                              <div className="row mt-1">
                                <div className="col-xl-3 col-lg-4 col-md-3 col-sm-3 col-3">
                                  <div className="product-thumbnail">
                                    <a href="">
                                      <img
                                        src={item.Product.thumbnail_image}
                                        alt="Product"
                                      />
                                      {/* <img src={item.variant_data.images[0]} alt="Ambaji Temple Banaskatha Gujarat" /> */}
                                    </a>
                                  </div>
                                </div>
                                <div className="col-xl-9 col-lg-8 col-md-9 col-sm-9 col-8">
                                  <div className="product-name-price">
                                    {/* <a href="">anc</a> */}
                                    {/* <a href="">{item?.product?.name}</a> */}
                                    <a
                                      href=""
                                      // onClick={(e) => {
                                      //   e.preventDefault();
                                      //   handleProductClick(item.Product.id);
                                      // }}
                                      onClick={() =>
                                        navigate(
                                          `/online-shopping-detail/${encodeURIComponent(
                                            item?.Product?.name
                                          )}`,
                                          {
                                            state: { id: item?.Product?.id }
                                          }
                                        )
                                      }
                                    >
                                      <b>{item?.Product?.name}</b>
                                    </a>
                                    <div className="d-flex justify-content-between align-cartData-center">
                                      <span className="price">
                                        <ins className="text-decoration-none mt-2">
                                          <span className="amount">
                                            {/* <bdi>
                                        <span>₹</span>
                                        {item.ProductsVariant.sell_rate}
                                      </bdi> */}
                                            <bdi>
                                              <span>₹</span>
                                              {
                                                item.ProductsVariant.sell_rate
                                              } /{' '}
                                              {
                                                item.ProductsVariant
                                                  .UnitOfMeasurement.name
                                              }
                                            </bdi>
                                          </span>
                                        </ins>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                              <div className="row align-items-center text-center mt-1">
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-4 col-5">
                                  <div className="main__number-box">
                                    <button
                                      className="main__number-button"
                                      onClick={() => {
                                        if (item.quantity === 1) return;
                                        removeToCart1(item);
                                      }}
                                    >
                                      <img
                                        src={IconMinus}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      />
                                    </button>
                                    <p className="main__p--number">
                                      {item.quantity}
                                    </p>
                                    <button
                                      className="main__number-button"
                                      onClick={() => addToCart(item)}
                                      disabled={isLoading}
                                      // disabled={
                                      //   item.ProductsVariant.stock_value.length > 0
                                      //     ? item.ProductsVariant.stock_value[0]
                                      //         .quantity <= item.qty
                                      //     : true
                                      // }
                                      // disabled={
                                      //   item.ProductsVariant.stock_value &&
                                      //   item.ProductsVariant.stock_value.length > 0
                                      //     ? item.ProductsVariant.stock_value[0]
                                      //         .quantity <= item.quantity
                                      //     : true
                                      // }

                                      //   old
                                      // disabled={
                                      //   item.variant_data.Stocks.length > 0
                                      //     ? item.variant_data.Stocks[0].quantity <=
                                      //       item.qty
                                      //     : true
                                      // }
                                    >
                                      <img
                                        src={IconPlus}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      />
                                    </button>
                                  </div>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
                                  <div className="product-subtotal">
                                    <span className="price">
                                      <ins className="text-decoration-none p-0">
                                        <span className="amount">
                                          <bdi>
                                            <span>₹</span>
                                            {item.quantity *
                                              item.ProductsVariant.sell_rate}
                                          </bdi>
                                        </span>
                                      </ins>
                                    </span>
                                  </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-2">
                                  <div className="product-subtotal">
                                    <div
                                      className="close"
                                      onClick={() => removeCart(item.id)}
                                    >
                                      <span className="remove"></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                    <hr className="my-4 border-dark" />
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="cart-collaterals">
                    <div className="collaterals-inners">
                      <div className="cart-totals">
                        <h5>Your Cart Summary</h5>
                        {/* <hr className="border-dark" />
                        <div className="cart-subtotal d-flex justify-content-between align-cartData- text-center">
                          <p className="text-uppercase m-0">SUBTOTAL</p>
                          <span className="price">
                            <ins className="text-decoration-none p-0">
                              <span className="amount">
                                <bdi>
                                  <span>₹</span>
                                  {subAmount}
                                </bdi>
                              </span>
                            </ins>
                          </span>
                        </div> */}
                        {/* <hr className="border-dark" /> */}
                        {/* <div className="shipping pb-0 d-flex justify-content-between align-cartData- text-center">
                          <p className="shipping-title m-0">Delivery Charges</p>
                          <span className="price">
                            <ins className="text-decoration-none p-0">
                              <span className="amount">
                                <bdi>
                                  <span>₹</span>
                                  {deliveryCharge}
                                </bdi>
                              </span>
                            </ins>
                          </span>
                        </div> */}
                        {/* <div className="cart-subtotal pb-2 d-flex justify-content-between align-items- text-center">
                          <p className="m-0">Flat Rate:</p>
                          <span className="price">
                            <ins className="text-decoration-none p-0">
                              <span className="amount">
                                <bdi>
                                  <span>₹</span>0
                                </bdi>
                              </span>
                            </ins>
                          </span>
                        </div> */}
                        <hr className="border-dark" />
                        <div className="order-total d-flex justify-content-between align-items-center">
                          <p className="text-uppercase m-0">TOTAL</p>
                          <span className="price">
                            <ins className="text-decoration-none p-0">
                              <span className="amount text-dark fw-semibold">
                                <bdi>
                                  <span>₹</span>
                                  {subAmount + deliveryCharge}
                                </bdi>
                              </span>
                            </ins>
                          </span>
                        </div>
                        <div
                          className="proceed-to-checkout"
                          style={{ marginBottom: '0px' }}
                        >
                          <button
                            className="text-center w-100 orangebtn"
                            onClick={() =>
                              navigate('/checkout-shop', {
                                state: {
                                  isDirectBuy: false,
                                  cartData: cartData
                                }
                                // state: { isDirectBuy: false },
                              })
                            }
                            //   navigate("/checkout", {
                            //     state: {
                            //       isDirectBuy: false,
                            //       cartData: cartData,
                            //       subAmount: subAmount,
                            //       deliveryCharge: deliveryCharge,
                            //       totalAmount: subAmount + deliveryCharge,
                            //     },
                            //   })
                            // }
                            // onClick={() => {
                            //   const stateData = {
                            //     isDirectBuy: false,
                            //     cartData: cartData,
                            //     subAmount: subAmount,
                            //     deliveryCharge: deliveryCharge,
                            //     totalAmount: subAmount + deliveryCharge,
                            //   };
                            //   localStorage.setItem(
                            //     "checkoutData",
                            //     JSON.stringify(stateData)
                            //   );
                            //   navigate("/checkout", {
                            //     state: stateData,
                            //   });
                            // }}
                          >
                            Proceed to Billing
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <section className="header-banner gold-banner">
            <div className="container">
              <h1>Cart</h1>
            </div>
          </section>
          <div className="container">
            <div className="empty-cart-list mt-5">
              <img
                src={emptyCartBag}
                className="img-fluid"
                alt="Ambaji Temple Banaskatha Gujarat"
              />
              <h6 className="mt-4">
                Your cart is empty - add divine blessings to your journey by
                selecting items from our store!
              </h6>

              <br />
              <a href="/online-ambaji-temple-shop" className="cool-link">
                Continue Shopping
              </a>
            </div>
          </div>
        </>
      )}
      <Arasuri />
      <Footer />
    </>
  );
};

export default Cart;

// old

// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { FaAngleLeft } from "react-icons/fa";
// import { Oval } from "react-loader-spinner";
// import CartService from "../../../Api_config/E_store/cart";
// import Footer from "../../../Components/footer";
// import CheckoutService from "../../../Api_config/E_store/checkout";
// import emptyCartBag from "../../../Assets/Images/E_store/EmptyBag.png";
// import LazyLoader from "../../../Components/lazyLoader";
// import IconMinus from "../../../Assets/Images/E_store/icon-minus.svg";
// import IconPlus from "../../../Assets/Images/E_store/icon-plus.svg";

// // import "../Shop/ShopDetailed/ShopDetailed.scss";
// import "../../../Css/gallery.scss";
// import "./cart.scss";
// import Arasuri from "../../../Components/arasuri";

// const Cart = () => {
//   const navigate = useNavigate();
//   const [number, setNumber] = useState(1);

//   const [loading, setLoading] = useState(true);
//   const [cartData, setCartData] = useState();
//   const [subAmount, setSubAmount] = useState(0);
//   const [deliveryCharge, setDeliveryCharge] = useState(0);

//   useEffect(() => {
//     const cart_data = CartService.getCartData();
//     setCartData(cart_data);

//     // cart_data?.forEach(
//     //   (el) =>
//     //     // setSubAmount((prev) => prev + el?.qty * el?.variant_data?.sell_rate),
//     //     setSubAmount((prev) => prev + el?.qty * el?.variant_data?.sell_rate)
//     //   // setNumber(prev + el?.qty)
//     // );

//     if (cart_data) {
//       let order_items = { order_items: cart_data };
//       CheckoutService.calculateDeliveryCharge(order_items).then(
//         (res) => {
//           if (res.data.success) {
//             setDeliveryCharge(res.data.deliveryCharges);
//             // setSubAmount(subAmount + res.data.deliveryCharges)
//           }
//         },
//         (error) => {
//           // console.log(error)
//         }
//       );
//     }
//     setTimeout(() => {
//       setLoading(false);
//     }, [3000]);
//   }, [setCartData, setSubAmount, setNumber]);
//   // console.log(deliveryCharge);

//   const removeCart = (data) => {
//     // console.log(data);
//     const cart_data = CartService.removeCartData(data);
//     setSubAmount(0);
//     setCartData(cart_data);
//     cart_data.forEach((el) =>
//       // setSubAmount((prev) => prev + el?.qty * el?.variant_data?.sell_rate),
//       setSubAmount((prev) => prev + el?.qty * el?.variant_data?.sell_rate)
//     );
//     if (cart_data) {
//       let order_items = { order_items: cart_data };
//       CheckoutService.calculateDeliveryCharge(order_items).then(
//         (res) => {
//           if (res.data.success) {
//             setDeliveryCharge(res.data.deliveryCharges);
//             // setSubAmount(subAmount + res.data.deliveryCharges)
//           }
//         },
//         (error) => {
//           //  console.log(error)
//         }
//       );
//     }
//   };

//   const addToCart = (data) => {
//     data.qty = 1;
//     // console.log(data);
//     const response = CartService.addToCart(data);
//     const cart_data = CartService.getCartData();
//     // setCartData(cart_data);
//     setSubAmount(0);
//     setCartData(cart_data);
//     cart_data.forEach((el) =>
//       // setSubAmount((prev) => prev + el?.qty * el?.variant_data?.sell_rate),
//       setSubAmount((prev) => prev + el?.qty * el?.variant_data?.sell_rate)
//     );
//     if (cart_data) {
//       let order_items = { order_items: cart_data };
//       CheckoutService.calculateDeliveryCharge(order_items).then(
//         (res) => {
//           if (res.data.success) {
//             setDeliveryCharge(res.data.deliveryCharges);
//             // setSubAmount(subAmount + res.data.deliveryCharges)
//           }
//         },
//         (error) => {
//           // console.log(error)
//         }
//       );
//     }
//     // navigate("/cart");
//   };
//   const removeToCart1 = (data) => {
//     data.qty = -1;
//     // console.log(data);
//     const response = CartService.addToCart(data);
//     const cart_data = CartService.getCartData();
//     // setCartData(cart_data);
//     setSubAmount(0);
//     setCartData(cart_data);
//     cart_data.forEach((el) =>
//       // setSubAmount((prev) => prev + el?.qty * el?.variant_data?.sell_rate),
//       setSubAmount((prev) => prev + el?.qty * el?.variant_data?.sell_rate)
//     );
//     if (cart_data) {
//       let order_items = { order_items: cart_data };
//       CheckoutService.calculateDeliveryCharge(order_items).then(
//         (res) => {
//           if (res.data.success) {
//             setDeliveryCharge(res.data.deliveryCharges);
//             // setSubAmount(subAmount + res.data.deliveryCharges)
//           }
//         },
//         (error) => {
//           // console.log(error)
//         }
//       );
//     }
//     // navigate("/cart");
//   };

//   return (
//     <>
//       {!loading ? (
//         <div>
//           {cartData?.length > 0 ? (
//             <>
//               <section className="header-banner shopOnline-banner">
//                 <div className="container">
//                   <h1>Cart</h1>
//                 </div>
//               </section>
//               <div className="cart">
//                 <div className="container">
//                   <div className="row">
//                     <div className="col-sm-12">
//                       <div
//                         className="back-to"
//                         style={{
//                           marginBottom: 30 + "px",
//                         }}
//                       >
//                         <a
//                           className="button outlined btn-sm"
//                           href="/shop-online"
//                           title="Back to Shop"
//                         >
//                           <i className="bi bi-chevron-left">
//                             <FaAngleLeft
//                               style={{
//                                 fontSize: 30 + "px",
//                                 marginRight: 5 + "px",
//                               }}
//                             />
//                           </i>
//                           Continue Shopping
//                         </a>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="container">
//                   <div className="row">
//                     <div className="col-lg-8 col-md-12 col-sm-12">
//                       <div className="row">
//                         {cartData?.map((item, i) => {
//                           return (
//                             <>
//                               <hr className="border-dark" key={i} />

//                               <div className="d-flex align-items-center Shopping-btn">
//                                 <div className="col-3">
//                                   <div className="product-thumbnail">
//                                     <a href="">
//                                       <img
//                                         src={item?.variant_data?.images[0]}
//                                         alt=""
//                                       />
//                                     </a>
//                                   </div>
//                                 </div>
//                                 <div className="col-3">
//                                   <div className="product-name-price">
//                                     <a href="">{item?.product_name}</a>
//                                     <div className="d-flex justify-content-between align-cartData-center">
//                                       <span className="price">
//                                         <ins className="text-decoration-none p-0">
//                                           <span className="amount">
//                                             <bdi>
//                                               <span>₹</span>
//                                               {/* {
//                                                       item?.variant_data
//                                                         ?.sell_rate
//                                                     } */}
//                                               {item?.variant_data?.sell_rate}
//                                             </bdi>
//                                           </span>
//                                         </ins>
//                                       </span>
//                                     </div>
//                                   </div>
//                                 </div>
//                                 <div className="col-3">
//                                   <div className="main__number-box">
//                                     <button
//                                       className="main__number-button"
//                                       onClick={() => {
//                                         if (item?.qty === 1) return;
//                                         removeToCart1(item);
//                                       }}
//                                     >
//                                       <img src={IconMinus} alt="Ambaji Temple Banaskatha Gujarat" />
//                                     </button>
//                                     <p className="main__p--number">
//                                       {item?.qty}
//                                     </p>
//                                     <button
//                                       className="main__number-button"
//                                       onClick={() => {
//                                         addToCart(item);
//                                       }}
//                                       disabled={
//                                         item?.variant_data?.Stocks?.length > 0
//                                           ? item?.variant_data?.Stocks[0]
//                                               ?.quantity <= item?.qty
//                                             ? true
//                                             : false
//                                           : true
//                                       }
//                                     >
//                                       <img src={IconPlus} alt="Ambaji Temple Banaskatha Gujarat" />
//                                     </button>
//                                   </div>
//                                 </div>
//                                 <div className="col-2">
//                                   <div className="product-subtotal">
//                                     <span className="price">
//                                       <ins className="text-decoration-none p-0">
//                                         <span className="amount">
//                                           <bdi>
//                                             <span>₹</span>
//                                             {/* {item?.qty *
//                                               item?.variant_data?.sell_rate} */}
//                                             {item?.qty *
//                                               item?.variant_data?.sell_rate}
//                                           </bdi>
//                                         </span>
//                                       </ins>
//                                     </span>
//                                   </div>
//                                 </div>
//                                 <div className="col-1">
//                                   <div className="product-subtotal">
//                                     <div
//                                       className="close"
//                                       onClick={() => removeCart(item)}
//                                     >
//                                       <span className="remove"></span>
//                                     </div>
//                                   </div>
//                                 </div>
//                               </div>
//                             </>
//                           );
//                         })}
//                         <hr className="my-4 border-dark" />
//                       </div>
//                     </div>
//                     <div className="col-lg-4 col-md-12 col-sm-12">
//                       <div className="cart-collaterals">
//                         <div className="collaterals-inners">
//                           <div className="cart-totals">
//                             <h5>Cart Totals</h5>
//                             <hr className="border-dark" />
//                             <div className="cart-subtotal d-flex justify-content-between align-cartData-center">
//                               <p className="text-uppercase m-0">SUBTOTAL</p>
//                               <span className="price">
//                                 <ins className="text-decoration-none p-0">
//                                   <span className="amount">
//                                     <bdi>
//                                       <span>₹</span>
//                                       {subAmount}
//                                     </bdi>
//                                   </span>
//                                 </ins>
//                               </span>
//                             </div>

//                             <hr className="border-dark" />
//                             <div className="shipping pb-0 d-flex justify-content-between align-cartData-center">
//                               <p className="shipping-title m-0">
//                                 Delivery Charges
//                               </p>
//                               <span className="price">
//                                 <ins className="text-decoration-none p-0">
//                                   <span className="amount">
//                                     <bdi>
//                                       <span>₹</span>
//                                       {deliveryCharge}
//                                     </bdi>
//                                   </span>
//                                 </ins>
//                               </span>
//                             </div>

//                             <div className="cart-subtotal pb-2 d-flex justify-content-between align-items-center">
//                               <p className="m-0">Flat Rate:</p>
//                               <span className="price">
//                                 <ins className="text-decoration-none p-0">
//                                   <span className="amount">
//                                     <bdi>
//                                       <span>₹</span>
//                                       {/* {flatAmount} */}0
//                                     </bdi>
//                                   </span>
//                                 </ins>
//                               </span>
//                             </div>

//                             <div className="collapse" id="collapseExample">
//                               <div className="shipping-calculator p-0">
//                                 <p className="form-row float-label">
//                                   <input
//                                     type="text"
//                                     className="input-text w-100"
//                                     placeholder="Town / City *"
//                                   />
//                                 </p>
//                                 <p className="form-row float-label">
//                                   <input
//                                     type="text"
//                                     className="input-text w-100"
//                                     placeholder="Postcode / ZIP *"
//                                   />
//                                 </p>

//                                 <p>
//                                   <button
//                                     type="submit"
//                                     className="button update-button outlined"
//                                   >
//                                     Update
//                                   </button>
//                                 </p>
//                               </div>
//                             </div>
//                             {/* collapsible change address  */}

//                             <hr className="border-dark" />

//                             <div className="order-total d-flex justify-content-between align-items-center">
//                               <p className="text-uppercase m-0">TOTAL</p>
//                               <span className="price">
//                                 <ins className="text-decoration-none p-0">
//                                   <span className="amount text-dark fw-semibold">
//                                     <bdi>
//                                       <span>₹</span>
//                                       {subAmount + deliveryCharge}
//                                     </bdi>
//                                   </span>
//                                 </ins>
//                               </span>
//                             </div>

//                             <div
//                               className="proceed-to-checkout"
//                               style={{ marginBottom: "0px" }}
//                             >
//                               <button
//                                 href=""
//                                 className="text-center w-100 orangebtn"
//                                 onClick={() =>
//                                   navigate("/checkout", {
//                                     state: { isDirectBuy: false },
//                                   })
//                                 }
//                               >
//                                 Proceed to Billing
//                               </button>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </>
//           ) : (
//             <>
//               <section className="header-banner gold-banner">
//                 <div className="container">
//                   <h1>Cart</h1>
//                 </div>
//               </section>
//               <div className="container">
//                 <div className="empty-cart-list mt-5">
//                   <img
//                     src={emptyCartBag}
//                     className="img-fluid"
//                     alt="Empty Bag"
//                   />
//                   <h6 className="mt-4">
//                     Oops! It seems like your cart is feeling a bit lonely right
//                     now.
//                   </h6>
//                   <p className="mb-0">
//                     Feel free to browse our amazing collection and add some
//                     items to your cart to make it happier!
//                   </p>
//                   <br />
//                   <a href="/online-shopping" className="cool-link">
//                     Continue Shopping
//                   </a>
//                 </div>
//               </div>
//             </>
//           )}
//           <Arasuri />
//           <Footer />
//         </div>
//       ) : (
//         <LazyLoader />
//       )}
//     </>
//   );
// };

// export default Cart;
