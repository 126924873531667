import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { SignUpSchema } from '../../_Constants/validations';
import Footer from '../../Components/footer';
import './auth.css';
import { routes } from '../../_Constants/routes';
import Arasuri from '../../Components/arasuri';
import SignUpService from '../../Api_config/Auth/signUp';
import UtilsService from '../../Api_config/Home_page/utils';

const SignUp = () => {
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [isShowToaster, setShowToaster] = useState(false);
  const [isNumberEditable, setNumberEditable] = useState(true);
  const [seconds, setSeconds] = useState(30);
  const [otp, setOTP] = useState();
  const [isOTPSend, setOTPSend] = useState(false);
  const [isResend, setResend] = useState(false);
  const [isNumberRegistered, setNumberRegistered] = useState(false);
  const [error, setError] = useState('');
  const [isShowForm, setShowForm] = useState(false);
  const [userData, setUserData] = useState();
  const [isOTPVerified, setIsOTPVerified] = useState();
  const mobileNumberRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email_id: '',
      primary_contact_number: '',
      primary_contact_code_id: 1,
      password: '',
      user_role_id: '34'
      // confirm_password: "",
    },
    validationSchema: SignUpSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      // console.log("Submitting values:", values); // Debugging log
      SignUpService.signUp(values)
        .then((res) => {
          if (res.data.success) {
            navigate(routes.login);
            toast.success(res.data.message, { autoClose: 1000 });

            // SignUpService.sendOTP(values?.primary_contact_number).then(
            //   (res) => {
            //     if (res.data.success) {
            //       setErrorMsg();
            //       navigate("/otp-verification", {
            //         state: {
            //           primary_contact_number: values?.primary_contact_number,
            //         },
            //       });
            //     }
            //   }
            // );
          }
        })
        .catch((error) => {
          const errors = error.response?.data?.error || [];
          if (errors.length > 0) {
            errors.forEach((err) => {
              toast.error(err.message, { autoClose: 1000 });
            });
          } else {
            const backendErrorMsg =
              error.response?.data?.message ||
              'An unexpected error occurred. Please try again.';
            setErrorMsg(backendErrorMsg);
            setShowToaster(true);
            toast.error(backendErrorMsg, { autoClose: 5000 });
          }
          navigate(routes?.error500);
        });
      // console.log(values)
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false
  });

  useEffect(() => {
    UtilsService.getCountry()
      .then((res) => {
        if (res.data.success) {
          setCountryList(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.log(error);
      });
  }, [setCountryList]);

  // number registered or not
  const checkForContactNumber = (event) => {
    if (formik?.values?.primary_contact_number) {
      SignUpService.getBySignUpKeyword(
        formik?.values?.primary_contact_number
      ).then((res) => {
        if (res.data.success) {
          if (res.data.data.length !== 0) {
            setError('This contact number is already registered.');
            toast.error('This contact number is already registered.', {
              autoClose: 1500
            });
          } else {
            setNumberRegistered(true);
            setNumberEditable(false);
            setUserData(res.data.data[0]);
            SignUpService.sendOTP(formik?.values?.primary_contact_number)
              .then((res) => {
                if (res.data.success) {
                  setOTPSend(true);
                  setSeconds(30);
                }
              })
              .catch((error) => {
                navigate(routes?.error500);
                toast.error(error.response?.data?.message, {
                  autoClose: 5000
                });
              });
          }
        }
      });
    }
  };

  // const checkForContactNumber = (event) => {
  //   if (formik?.values?.primary_contact_number) {
  //     SignUpService.getBySignUpKeyword(
  //       formik?.values?.primary_contact_number
  //     ).then((res) => {
  //       if (res.data.success) {
  //         setNumberRegistered(true);
  //         setNumberEditable(false);
  //         setUserData(res.data.data[0]);
  //         // console.log(res.data.data[0].UserAddresses);
  //         // if (res.data.data[0].UserAddresses.length > 0) {
  //         //   setUserAddress(res.data.data[0].UserAddresses[0]);
  //         // }
  //         SignUpService.sendOTP(formik?.values?.primary_contact_number)
  //           .then((res) => {
  //             if (res.data.success) {
  //               setOTPSend(true);
  //               setSeconds(30);
  //             }
  //           })
  //           .catch((error) => {
  //        navigate(routes?.error500);
  //             toast.error(error.response?.data?.message, { autoClose: 1500 });
  //           });
  //       }
  //     });
  //   }
  // };

  // for Number exists or not

  const [organizerContact, setOrganizerContact] = useState('');
  // const handleOrganizerContactChange = (e) => {
  //   const newValue = e.target.value;
  //   setOrganizerContact(newValue);
  //   setError(""); // Clear the error message when the user changes the input

  //   formik.handleChange(e);

  //   // Perform the check for contact number availability
  //   if (newValue.length === 10) {
  //     // Assuming the contact number should be 10 digits
  //     checkContactNumberAvailability(newValue);
  //   }
  // };

  // const checkContactNumberAvailability = async (primary_contact_number) => {
  //   try {
  //     const response = await SignUpService.getBySignUpKeyword(
  //       primary_contact_number
  //     ); // Replace with your actual API service method
  //     if (response.data.isTaken) {
  //       setError("This contact number is already taken.");
  //     }
  //   } catch (error) {
  //     console.error("Error checking contact number availability:", error);
  //   }
  // };
  /**
   * Verify OTP
   * @param primary_contact_number
   */
  const verifyOTP = () => {
    // if (otp?.length === 6) {
    //   formik.setFieldValue("first_name", "");
    //   formik.setFieldValue("last_name", "");
    //   formik.setFieldValue("email_id", "");
    //   formik.setFieldValue("password", "");
    //   formik.setFieldValue("user_role_id", "");
    //   formik.setFieldValue("primary_contact_code_id", "");
    //   formik.setFieldValue("primary_contact_number", "");

    // formik?.resetForm()

    SignUpService.verifyOTP(formik?.values?.primary_contact_number, otp)
      .then((res) => {
        if (res.data.success) {
          if (userData) {
            // setOpenModal(true);
          }
          // setShowForm(true);
          setIsOTPVerified(true);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        toast.error(error.response?.data?.message, { autoClose: 1500 });
      });
    // }
  };
  // ----------

  /**
   * Resend OTP
   * @param primary_contact_number
   */
  const resendOTP = () => {
    SignUpService.sendOTP(formik?.values?.primary_contact_number)
      .then((res) => {
        if (res.data.success) {
          setOTPSend(true);
          setSeconds(30);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        toast.error(error.response?.data?.message, { autoClose: 1500 });
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const focusOnMobileNumber = () => {
    if (mobileNumberRef.current) {
      mobileNumberRef.current.focus();
    }
  };

  // ----------

  return (
    <>
      <section className="header-banner contactus-banner">
        <div className="container">
          <h1>Sign Up</h1>
        </div>
      </section>
      <div className="auth">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-10 col-sm-12 col-12">
              <div className="card">
                <form action="POST" onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-12 col-md-6 form-group">
                      <label htmlFor="first_name">
                        First Name <span className="red-text">*</span>
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        className="form-control"
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldTouched('first_name', true, true);
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          formik.setFieldTouched('first_name', true, true);
                        }}
                        value={formik.values.first_name}
                      />
                      {formik?.touched?.first_name &&
                        formik?.errors?.first_name && (
                          <p className="red-text">
                            {formik?.errors?.first_name}
                          </p>
                        )}
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <label htmlFor="last_name">
                        Last Name <span className="red-text">*</span>
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        className="form-control"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.last_name}
                      />
                      {formik.touched.last_name && formik.errors.last_name && (
                        <p className="red-text">{formik.errors.last_name}</p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 form-group">
                      <label htmlFor="email_id">Email Id</label>
                      <input
                        type="email_id"
                        name="email_id"
                        id="email_id"
                        className="form-control"
                        onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          formik.setFieldValue(
                            'email_id',
                            e.target.value.toLowerCase()
                          );
                        }}
                        value={formik.values.email_id}
                      />
                      {formik.touched.email_id && formik.errors.email_id && (
                        <p className="red-text">{formik.errors.email_id}</p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-4">
                      <div className="form-group">
                        <label htmlFor="primary_contact_code_id">
                          Code <span className="red-text">*</span>
                        </label>
                        <select
                          className="form-control"
                          name="primary_contact_code_id"
                          id="primary_contact_code_id"
                          onChange={formik?.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.primary_contact_code_id}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          {countryList?.map((item, i) => {
                            return (
                              <option
                                value={item?.id}
                                selected={item?.phone_code == '91'}
                                key={i}
                              >
                                +{item?.phone_code}
                              </option>
                            );
                          })}
                        </select>
                        {formik.touched.primary_contact_code_id &&
                          formik.errors.primary_contact_code_id && (
                            <p className="red-text">
                              {formik.errors.primary_contact_code_id}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="col-md-8 col-8">
                      <div className="form-group">
                        <label htmlFor="primary_contact_number">
                          Mobile Number <span className="red-text">*</span>
                        </label>
                        <input
                          type="text"
                          inputMode="numeric"
                          name="primary_contact_number"
                          id="primary_contact_number"
                          className="form-control"
                          onChange={formik.handleChange}
                          // value={organizerContact}
                          readOnly={!isNumberEditable}
                          value={formik.values.primary_contact_number}
                          // onChange={handleOrganizerContactChange}
                          onBlur={formik.handleBlur}
                          ref={mobileNumberRef}
                        />
                        {formik.touched.primary_contact_number &&
                          formik.errors.primary_contact_number && (
                            <p className="red-text">
                              {formik.errors.primary_contact_number}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group">
                      <div className="col-md-12">
                        {isNumberEditable && !isOTPVerified ? (
                          <button
                            type="button"
                            className="whitebtn"
                            onClick={() => checkForContactNumber()}
                          >
                            Verify Number
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="whitebtn"
                            onClick={() => {
                              setNumberEditable(true);
                              setNumberRegistered(false);
                              setShowForm(false);
                              setIsOTPVerified(false);
                              focusOnMobileNumber();
                              setOTP(''); // Reset OTP
                              formik.setFieldValue('otp', ''); // Reset OTP in Formik
                            }}
                          >
                            Edit Number
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {isNumberRegistered &&
                    !isNumberEditable &&
                    !isOTPVerified && (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div
                              className="row align-items-end"
                              style={{ rowGap: '15px' }}
                            >
                              <div className="col-md-4 col-12">
                                <label htmlFor="otp">OTP</label>
                                <input
                                  type="text"
                                  inputMode="numeric"
                                  minLength="6"
                                  maxLength="6"
                                  pattern="[0-9]*" // Only allow numeric input
                                  name="otp"
                                  id="otp"
                                  className="form-control"
                                  placeholder="_  _  _  _  _  _"
                                  value={otp}
                                  onChange={(event) => {
                                    const inputValue =
                                      event.target.value.replace(/\D/g, ''); // Remove non-digit characters
                                    setOTP(inputValue.slice(0, 6)); // Limit to 6 digits and update state
                                    setResend(false); // Reset the resend state if needed
                                  }}
                                />
                              </div>
                              <div className="col-md-8 col-12">
                                <button
                                  type="button"
                                  className="orangebtn"
                                  onClick={() => verifyOTP()}
                                  disabled={otp?.length !== 6}
                                >
                                  Verify OTP
                                </button>

                                {isOTPSend && seconds == '00' && (
                                  <button
                                    type="button"
                                    className="whitebtn ms-3"
                                    onClick={() => [
                                      resendOTP(),
                                      setOTP(''),
                                      setResend(true)
                                    ]}
                                  >
                                    Resend OTP
                                  </button>
                                )}
                              </div>
                              {!isResend &&
                                (otp?.length > 6 || otp?.length < 6) && (
                                  <p className="red-text mb-0">
                                    OTP must be a 6 digits number
                                  </p>
                                )}

                              {isOTPSend && seconds > 0 && (
                                <p className="mb-0">
                                  Resend OTP in
                                  {seconds < 10
                                    ? ` 0${seconds}`
                                    : ` ${seconds}`}{' '}
                                  sec
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {isOTPVerified ? (
                    <>
                      <div className="row">
                        <div className="col-12 col-lg-6 col-md-6 form-group">
                          <label htmlFor="password">
                            Password <span className="red-text">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type={showPassword ? 'text' : 'password'}
                              name="password"
                              id="password"
                              className="form-control"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.password}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                {showPassword ? (
                                  <FaEyeSlash
                                    onClick={() => setShowPassword(false)}
                                  />
                                ) : (
                                  <FaEye
                                    onClick={() => setShowPassword(true)}
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                          {formik.touched.password &&
                            formik.errors.password && (
                              <p className="red-text">
                                {formik.errors.password}
                              </p>
                            )}
                        </div>
                        <div className="col-12 col-lg-6 col-md-6 form-group">
                          <label htmlFor="confirm_password">
                            Confirm Password <span className="red-text">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type={showConfirmPassword ? 'text' : 'password'}
                              name="confirm_password"
                              id="confirm_password"
                              className="form-control"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                {showConfirmPassword ? (
                                  <FaEyeSlash
                                    onClick={() =>
                                      setShowConfirmPassword(false)
                                    }
                                  />
                                ) : (
                                  <FaEye
                                    onClick={() => setShowConfirmPassword(true)}
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                          {formik.touched.confirm_password &&
                            formik.errors.confirm_password && (
                              <p className="red-text">
                                {formik.errors.confirm_password}
                              </p>
                            )}

                          {formik.values?.confirm_password?.length > 2 &&
                            formik?.values?.password !==
                              formik?.values?.confirm_password && (
                              <p className="red-text">
                                Passwords don't match. Please try again.
                              </p>
                            )}
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="form-footer mt-3">
                    <button type="submit" className="orangebtn w-100">
                      Sign Up
                    </button>
                    <p className="text-center mt-4">
                      Already have an account?{' '}
                      <span
                        className="Span-link"
                        onClick={() => navigate(routes?.login)}
                      >
                        Login here{' '}
                      </span>
                    </p>
                  </div>

                  {/* {errorMsg && <p className="red-text">{errorMsg}</p>} */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowToaster ? (
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      ) : null}
      <Arasuri />
      <Footer />
    </>
  );
};
export default SignUp;
