import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from '../../../../Components/footer';
import LazyLoader from '../../../../Components/lazyLoader';
import parking from '../../../../Assets/Images/51_shaktipith/parking-img.png';
import Arasuri from '../../../../Components/arasuri';
import Error404 from '../../../../Pages/error404';

const ParkingSolution = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });

  //-----page-close-404-error-logic-p-----
  // if (true) {
  //   return <Error404 />;
  // }
  //-----page-close-404-error-logic
  return (
    <>
      <Helmet>
        <title>Ambaji Temple - 51 Shaktipith Mahotsav</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Get detailed information of 51 Shaktipith Mahotsav."
        />
        <meta name="keywords" content="51 Shaktipith Mahotsav Ambaji" />
        <meta name="author" content="51 Shaktipith Mahotsav" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      {!isLoading ? (
        <div className="main-wrapper">
          <section className="header-banner shaktipith-banner">
            <div className="container">
              <h1>પ૧ શક્તિપીઠ મહોત્સવ પાર્કિંગ સુવિધા-૨૦૨૪</h1>
            </div>
          </section>
          <section className="about-page">
            <div className="container">
              <div className="about-main">
                <div className="templetop">
                  <div className="row align-items-center">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-6">
                      <div className="templeimg">
                        <img src={parking} alt="parking" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default ParkingSolution;
