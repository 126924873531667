import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import Footer from '../../../Components/footer';
import LazyLoader from '../../../Components/lazyLoader';
import ParkingArea from '../../../Assets/Images/51_shaktipith/parking-area.svg';
import yagna from '../../../Assets/Images/E_store/yagna.svg';
import SpotlightLight from '../../../Assets/Images/51_shaktipith/SpotlightLight.svg';
import calendar from '../../../Assets/Images/51_shaktipith/calendar.svg';
import MasalaRed from '../../../Assets/Images/51_shaktipith/MasalaRed.svg';
import festival from '../../../Assets/Images/51_shaktipith/festival.svg';
import Arasuri from '../../../Components/arasuri';
import Error404 from '../../../Pages/error404';

const ShaktipithMahotsav = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });
  //-----page-close-404-error-logic-p-----
  // if (true) {
  //   return <Error404 />;
  // }
  //-----page-close-404-error-logic-p-----
  return (
    <>
      <Helmet>
        <title>
          51 Shaktipeeth Mahotsav Yagna Pooja Booking - Ambaji Temple, Gujarat
        </title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Book 51 Shaktipeeth Mahotsav for the Yagna Pooja at Ambaji Temple during the 51 Shaktipeeth Mahotsav. Participate in this divine event at Ambaji Temple and Gabbar Hill, Gujarat. Reserve your online booking for an unforgettable spiritual experience. Only One Slot Per Devotee is Available Per Day."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, 51 Shaktipeeth Mahotsav, Yagna Pooja Booking, Shaktipeeth Event, Gabbar Hill Pooja, Ambaji Yagna, Gujarat Shaktipeeth, Online Yagna Booking, Ambaji Temple Events, Spiritual Yagna Gujarat, Shaktipeeth Festival, Temple Pooja Booking, Ambaji Temple Events Registration"
        />
        <meta
          name="author"
          content="Ambaji Temple, Banaskatha Gujarat | BinTech Services"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      {!isLoading ? (
        <div className="main-wrapper">
          <section className="header-banner shaktipith-banner">
            <div className="container">
              <h1>
                પ૧ શક્તિપીઠ મહોત્સવ-૨૦૨પ
                <br />
                (૦૯/૦૨/૨૦૨પ થી ૧૧/૦૨/૨૦૨પ)
              </h1>
            </div>
          </section>
          <section className="about-page">
            <div className="container">
              <div className="about-main">
                <div className="templetop">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="templeimg">
                        <img
                          src="https://storage.googleapis.com/ambaji-temple.appspot.com/1692249532794_Gabbar.jpeg "
                          alt="Ambaji Temple Banaskatha Gujarat"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h2>
                        51 શક્તિપીઠ <span>મહોત્સવ</span>
                      </h2>
                      <p>
                        ભારતભરમાં યાત્રાઘામ તરીકે પ્રસિધ્ધ એવુ શ્રી આરાસુરી
                        અંબાજી માતા મંદિર ગુજરાત રાજ્યના બનાસકાંઠા જીલ્લાના
                        દાંતા તાલુકામાં આવેલ. જે એક પુરાણપ્રસિદ્ધ શક્તિપીઠ તરીકે
                        ઓળખાય છે. અંબાજી તીર્થમાં લાખો ભાવિક ભક્તો મા ના
                        દર્શનાર્થે આવે છે. દેવસ્થાન ટ્રસ્ટ અને રાજ્ય સરકાર
                        ધ્વારા અથાગ પ્રયતો કરી મંદિરનો જીર્ણોધ્ધાર કરી શિખરની 61
                        ફૂટ સુવર્ણમયની કામગીરી પૂર્ણ કરી સુવર્ણ 358 સુવર્ણ કળશો
                        પ્રતિષ્ઠિત કરેલ છે જે ભારત ભરમાં એક માત્ર શક્તિપીઠ છે.
                        51 શક્તિપીઠોમાં હૃદયસમુ અંબાજી લાખો ભક્તોની શ્રદ્ધાનું
                        કેન્દ્ર છે. ૫૧ શકિતપીઠ પરિકમામાં શ્રધ્ધાળુઓ ર.પ કિ.મી.
                        લાંબા પરિકમા માર્ગ પર તમામ ૫૧ મંદિરના દર્શન કરી શકશે.
                        ભારત ઉપરાંત નેપાળ, પાકીસ્તાન, શ્રીલંકા, તિબેટ અને
                        બાંગ્લાદેશમાં તથા ભારતના વિભિન્ન રાજયો જેમ કે, પશ્વિમ
                        બંગાળ, બિહાર, ત્રિપૂરા, મેઘાલયુ, આસામ, તામીલનાડુ,
                        મહારાષ્ટ્ર, મઘ્યપ્રદેશ, આંધ્રપ્રદેશ, ઉત્તરપ્રદેશ,
                        હિમાંચલપ્રદેશ, હરિયાણા, પંજાબ, ઓરિસ્સા, રાજસ્થાન,
                        કાશ્મીર જેવા રાજયોમાં સ્થિત મૂળ શકિતપીઠોની આબેહૂબ
                        પ્રતિકૃતિઓનું ગબ્બર પ૧ શક્તિપીઠુ પરિકમા માર્ગમાં નિમણિ
                        કરવામાં આવેલ છે.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="service-section"
            style={{ margin: '0px 0px', padding: '0px 0px' }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <h2 style={{ margin: '0px 0px' }}>
                    ભક્તો <span>માટે ની સુવિધા</span>
                  </h2>
                  <div className="service-list">
                    <div className="row">
                      {/* DAILY ROUTINE  */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <a href="/daily-routine">
                          <div
                            className="servicebox"
                            style={{ margin: '10px 0px' }}
                          >
                            <div className="service-icon">
                              <img
                                src={calendar}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h5>દૈનિક મહોત્સવો</h5>
                            <p>દિવસ મુજબનું શેડ્યૂલ</p>
                          </div>
                        </a>
                      </div>
                      {/* ------------- */}

                      {/* YAGNA BOOKING  */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <a href="/51-shaktipeeth-yagna-booking-terms-and-conditions">
                          <div
                            className="servicebox"
                            style={{ margin: '10px 0px' }}
                          >
                            <div className="service-icon">
                              <img
                                src={yagna}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h5>યજ્ઞનું બુકિંગ</h5>
                            <p>પરિક્રમા માટે યજ્ઞ બુકિંગ</p>
                          </div>
                        </a>
                      </div>
                      {/* ------------- */}

                      {/* PARKING  */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <a href="/parking-solution">
                          <div
                            className="servicebox"
                            style={{ margin: '10px 0px' }}
                          >
                            <div className="service-icon">
                              <img
                                src={ParkingArea}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h5>પાર્કિંગ</h5>
                            <p>
                              {/* મંદિરો દ્વારા ઓનલાઈન પાર્કિંગ સુવિધા તમને તમારી
                              કાર માટે જગ્યા પ્રીબુક કરવામાં મદદ કરે છે. */}
                              ૫૧ શક્તિપીઠ પરિક્રમા મહોત્સવ દરમિયાન વિનામૂલ્યે
                              પાર્કિંગ માટેના સ્થળ
                            </p>
                          </div>
                        </a>
                      </div>
                      {/* ------------- */}

                      {/* BHOJAN VYAVSTHA  */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div
                          className="servicebox"
                          style={{ margin: '10px 0px' }}
                        >
                          <div className="service-icon">
                            <img
                              src={MasalaRed}
                              alt="Ambaji Temple Banaskatha Gujarat"
                            ></img>
                          </div>
                          <h5>ભોજન વ્યવસ્થા</h5>
                          <p>
                            મા આરાસુરી અંબાના મંદિરે આવતા તમામ ભક્તોને દરરોજ
                            ભોજન પ્રસાદ પીરસવો.
                          </p>
                        </div>
                      </div>
                      {/* ------------- */}

                      {/* SANSKRUTIK  */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div
                          className="servicebox"
                          style={{ margin: '10px 0px' }}
                        >
                          <div className="service-icon">
                            <img
                              src={festival}
                              alt="Ambaji Temple Banaskatha Gujarat"
                            ></img>
                          </div>
                          <h5>સાંસ્કૃતિક કાર્યક્રમ</h5>
                          <p>સાંસ્કૃતિક કાર્યક્રમો</p>
                        </div>
                      </div>
                      {/* ------------- */}

                      {/* LIGHT ANS SOUND SHOW  */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div
                          className="servicebox"
                          style={{ margin: '10px 0px' }}
                        >
                          <div className="service-icon">
                            <img
                              src={SpotlightLight}
                              alt="Ambaji Temple Banaskatha Gujarat"
                            ></img>
                          </div>
                          <h5>લાઇટ એન્ડ સાઉન્ડ શો</h5>
                          <p>
                            અંબાજી મંદિર ખાતે મનમોહક લાઇટ એન્ડ સાઉન્ડ શોનો અનુભવ
                            કરો, જે ઇતિહાસ અને આધ્યાત્મિકતા દ્વારા મંત્રમુગ્ધ
                            કરી દેનારી યાત્રા છે.
                          </p>
                        </div>
                      </div>
                      {/* ------------- */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default ShaktipithMahotsav;
