import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Swiper, SwiperSlide } from 'swiper/react';

// import Swiper, { Navigation,Scrollbar } from "swiper";
// SwiperCore.use([Mousewheel]);

// import SwiperCore, { Mousewheel, Pagination, Scrollbar, A11y, Autoplay, EffectFade, Navigation } from 'swiper/core';
// import 'swiper/swiper.min.css';
// import 'swiper/components/pagination/pagination.min.css';
// import 'swiper/components/navigation/navigation.min.css';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade
  // Mousewheel,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
// import { FaArrowUp } from "react-icons/fa";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

// services
import CmsService from '../../Api_config/Home_page/cms';
import BlogService from '../../Api_config/Home_page/blog';
import ProductService from '../../Api_config/Products/product';
import ScheduleService from '../../Api_config/Home_page/schedule';
import EventService from '../../Api_config/Home_page/event';
// import UtilsService from "../../Api_config/Home_page/utils";

// components
import Footer from '../../Components/footer';
import LazyLoader from '../../Components/lazyLoader';
import TemplesSection2 from './templesSection2';
import { routes } from '../../_Constants/routes';

// images

// ---------------social media images------------
import facebook from '../../Assets/Images/Social media/facebook.svg';
import instagram from '../../Assets/Images/Social media/instagram.svg';
import twiter from '../../Assets/Images/Social media/twitter.svg';
import youtube from '../../Assets/Images/Social media/youtube.svg';
import SocialImages from '../../Assets/Images/Social media/SocialImages.svg';

// ---------------other images------------

import prasad from '../../Assets/Images/Home_page/Ambaji_HomePage_Prasad.png';
import temple1 from '../../Assets/Images/Home_page/temple1.png';
import ambajitemple1 from '../../Assets/Images/Home_page/linear.png';
import carBlack from '../../Assets/Images/Home_page/car-black.svg';
import carWhite from '../../Assets/Images/Home_page/car-white.svg';
import planeBlack from '../../Assets/Images/Home_page/plane-black.svg';
import planeWhite from '../../Assets/Images/Home_page/plane-white.svg';
import trainBlack from '../../Assets/Images/Home_page/train-black.svg';
import trainWhite from '../../Assets/Images/Home_page/train-white.svg';
import TempleIcon from '../../Assets/Images/Home_page/TempleIcon.svg';
import SunriseRed from '../../Assets/Images/Home_page/SunriseRed.svg';
import AftrnoonRed from '../../Assets/Images/Home_page/AftrnoonRed.svg';
import EveningRed from '../../Assets/Images/Home_page/EveningRed.svg';
// import DonationRed from "../../Assets/Images/Home_page/DonationRed.svg";
// import LadduRed from "../../Assets/Images/Home_page/LadduRed.svg";
// import MapsRed from "../../Assets/Images/Home_page/MapsRed.svg";
// import ParkingRed from "../../Assets/Images/Home_page/ParkingRed.svg";
import AvashRed from '../../Assets/Images/Home_page/AvashRed.svg';
import MasalaRed from '../../Assets/Images/Home_page/MasalaRed.svg';
import UddanKhatola from '../../Assets/Images/Home_page/UddanKhatola.svg';
import SpotlightLight from '../../Assets/Images/Home_page/SpotlightLight.svg';
import RedTemple from '../../Assets/Images/Home_page/RedTemple.svg';
import PrayIcon from '../../Assets/Images/Home_page/PrayIcon.svg';
import MoneyDonation from '../../Assets/Images/Home_page/AmbajiTemple_MoneyDonation.png';

// import yagna from "../../Assets/Images/E_store/yagna.svg";
// import yagnaIcon from "../../Assets/Images/E_store/yagnaIcon.svg";
// import dhaja from "../../Assets/Images/E_store/dhaja.svg";

// css
import './home.scss';
import '../../Css/global.scss';
import '../../Css/innerPages.scss';
// import MobileHome from "./mobileHome";
import TithiService from '../../Api_config/Home_page/tithi';

// const ComingSoonComponent = (
//   <div className="btn-group">
//     <NavLink to=" " className="orangebtn">
//       ટૂંક સમયમાં આવી રહ્યું છે
//     </NavLink>
//   </div>
//   // <p className="card-text custom-coming-soon">ટૂંક સમયમાં આવી રહ્યું છે</p>
// );

const Home = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [cmsData, setCmsData] = useState();
  const [blogData, setBlogData] = useState();
  const [productData, setProductData] = useState();
  const [scheduleData, setScheduleData] = useState([]);
  const [pastData, setPastData] = useState([]);
  const [futureData, setFutureData] = useState([]);
  const [showPreviousEvents, setShowPreviousEvents] = useState(false);
  const [showUpcomingEvents, setShowUpcomingEvents] = useState(true);
  const [currentDate, setCurrentDate] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [tithi, setTithi] = useState();
  const [selectedUtsav, setSelectedUtsav] = useState(true);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  //   scroll to top button
  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth'
  //   });
  // };

  window.addEventListener('scroll', toggleVisible);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  // ---------- //

  useEffect(() => {
    // cms data api point
    const id = 1;
    CmsService.getAll().then((res) => {
      // console.log(res.data);
      setCmsData(res.data.data);
      setLoading(false);
    });

    // blog data api point
    BlogService.getAll().then((res) => {
      // console.log(res.data);
      setBlogData(res.data.data);
    });

    // product data api point
    ProductService.getAll().then((res) => {
      // console.log(res.data.data);
      setProductData(res.data.data);
    });

    // schedule data api point
    ScheduleService.getById(5)
      .then((res) => {
        setScheduleData(res.data.data);
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.error("Error fetching schedule data:", error);
      });

    // event data api point
    EventService.getByTimeFrameFuture(id).then((res) => {
      setFutureData(res.data.data);
    });
    EventService.getByTimeFramePast(id).then((res) => {
      setPastData(res.data.data);
    });

    // Tithi data api point
    TithiService.getTithi()
      .then((res) => {
        if (res.data.success) {
          setTithi(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.log(error);
      });
  }, []);

  // for showing time in section 4

  useEffect(() => {
    const interval = setInterval(() => {
      const date = new Date();
      const formattedDate = `${date.getDate()} ${date.toLocaleString(
        'default',
        { month: 'short' }
      )} / ${date.getFullYear()}`;
      setCurrentDate(formattedDate);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // ------------------- //

  const handlePreviousEventsClick = () => {
    setSelectedUtsav(!selectedUtsav);
    setShowPreviousEvents(true);
    setShowUpcomingEvents(false);
  };

  const handleUpcomingEventsClick = () => {
    setSelectedUtsav(!selectedUtsav);
    setShowPreviousEvents(false);
    setShowUpcomingEvents(true);
  };

  // const section_names = [
  //   // "Slider",
  //   '',
  //   'મંદિરો',
  //   'પરિક્રમા',
  //   'સમય સૂચિ',
  //   'ઇ-પરિક્રમા',
  //   'સેવાઓ',
  //   'દાન',
  //   'શોપિંગ',
  //   'પ્રસાદ',
  //   // "બ્લોગ્સ",
  //   'ટ્રાન્સપોટેશન્સ',
  //   // "Aarasur",
  //   // "Footer",
  //   '',
  //   ''
  // ];

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         const response = await FeaturedService.getAll();
  //         // console.log(response, "featured reponse ");
  //         setFeaturedData(response.data.data);
  //         const ambajiId = 1;
  //         const gabbarId = 2;
  //         const shaktipithId = 3;

  //         // Fetch data for Ambaji
  //         const ambajiResponse = await FeaturedService.getById(ambajiId);
  //         setAmbajiData(ambajiResponse.data.data[0]);

  //         // Fetch data for Gabbar
  //         const gabbarResponse = await FeaturedService.getById(gabbarId);
  //         setGabbarData(gabbarResponse.data.data[0]);

  //         // Fetch data for Shaktipith
  //         const shaktipithResponse = await FeaturedService.getById(shaktipithId);
  //         setShaktipithData(shaktipithResponse.data.data[0]);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     };

  //     fetchData();
  //   }, []);

  // for live video streaming

  const currentTime = new Date();
  // console.log("Current Time:", currentTime.toLocaleTimeString(), currentTime);

  // const currentTime = new Date();

  // Format current time as "HH:mm:ss"
  // const hours = currentTime.getHours().toString().padStart(2, '0');
  // const minutes = currentTime.getMinutes().toString().padStart(2, '0');
  // const seconds = currentTime.getSeconds().toString().padStart(2, '0');

  // const cTime = `${hours}:${minutes}:${seconds}`;
  // let cTime = currentTime.toLocaleTimeString();

  useEffect(() => {
    // console.log(currentTime);
    // CmsService.getAllVideoStream().then((res) => {
    //   // console.log(res.data.data);
    //   if (res.data.data[0].start_time && res.data.data[0].end_time) {
    //     // console.log(
    //     //   cTime >= res.data.data[0].start_time &&
    //     //     cTime <= res.data.data[0].end_time,
    //     // );
    //     // console.log(
    //     //   "vvvv",
    //     //   res.data.data[0].start_time,
    //     //   res.data.data[0].end_time,
    //     //   cTime,
    //     // );
    //     if (
    //       cTime >= res.data.data[0].start_time &&
    //       cTime <= res.data.data[0].end_time
    //     ) {
    //       setShowSpecificVideo(true);
    //       setStreamUrl(res.data.data[0].stream_link);
    //     }
    //   }
    // });
  }, []);
  return (
    <>
      <Helmet>
        <title>Ambaji Temple | Banaskantha, Gujarat</title>
        <meta
          name="description"
          content="Managed by Shri Ambaji Mata Devasthan Trust, Ambaji Temple is one of the 51 Shakti
Peethas where the heart of Goddess Sati fell. Experience live darshan, view temple and aarti
timing, donate online, contribute to the Suvarna Shikhar Gold, book Pavdi Puja, Dhaja, and
Yagna through our website. Join us in worship and devotion from anywhere in the world."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, Ambaji Temple queries, Ambaji Temple information, Temple contact details,
Banaskantha temple contact, Religious assistance India, Ambaji Temple, Ambaji Mataji,
Ambaji Gujarat, Ambaji Darshan, Ambaji Temple Timing, Ambaji Temple Gabbar"
        />
        <meta
          name="author"
          content="Ambaji Temple and Gabbar Banaskantha Gujarat | BinTech Services Ahmedabad"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!isLoading ? (
        <>
          <div className="stacked-social-icons">
            <div className={`social-icons ${expanded ? 'expanded' : ''}`}>
              <a
                href="https://www.facebook.com/ambajitempletrustofficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={facebook}
                  alt="Ambaji Temple Banaskatha Gujarat"
                  className="img-fluid"
                ></img>
              </a>
              <a
                href="https://twitter.com/templeambaji"
                target="_blank"
                rel="noopener noreferrer"
                className="twitter-icon"
              >
                <img
                  src={twiter}
                  alt="Ambaji Temple Banaskatha Gujarat"
                  className="img-fluid"
                ></img>
              </a>
              <a
                href="https://instagram.com/ambajitempletrustofficial"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={instagram}
                  alt="instagram"
                  className="img-fluid"
                ></img>
              </a>
              <a
                href="https://youtube.com/@ambajitempleofficial3283"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={youtube}
                  alt="Ambaji Temple Banaskatha Gujarat"
                  className="img-fluid"
                ></img>
              </a>
            </div>
            <button className="whatsapp-icon" onClick={toggleExpand}>
              <img
                src={SocialImages}
                alt="SocialImages"
                style={{ width: '38px', height: '38px' }}
              />
            </button>
          </div>
          {/* --------------------- Slider: Section-1 */}
          <div className="d-none d-lg-block">
            <SwiperSlide>
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                  type: 'bullets',
                  direction: 'horizontal'
                }}
                modules={[
                  Navigation,
                  Pagination,
                  Scrollbar,
                  A11y,
                  Autoplay,
                  EffectFade
                ]}
                slidesPerView={1}
                loop
                effect="fade"
                speed={500}
                fadeEffect={{
                  crossFade: true
                }}
                className="mySwiper"
                autoplay={{
                  delay: 2000
                }}
              >
                {cmsData?.map((item, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <section className="mainbanner">
                        <div className="bannerslider">
                          <div className="banner-item">
                            <div className="bannerimg">
                              <img
                                src={item?.desk_image}
                                alt="temple images"
                              ></img>
                            </div>
                            {/* <div className="bannercontent">
                                <div className="container">
                                  <div className="contentinner"></div>
                                </div>
                              </div> */}
                          </div>
                        </div>
                      </section>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </SwiperSlide>
          </div>

          {/*--------------------------------------------------------------------- (Mobile) home page slider start --------------------------------------------------------------------- */}
          <div className="d-block d-lg-none">
            <SwiperSlide>
              <Swiper
                spaceBetween={10}
                pagination={{
                  clickable: true,
                  type: 'bullets',
                  direction: 'horizontal'
                }}
                modules={[
                  Navigation,
                  Pagination,
                  Scrollbar,
                  A11y,
                  Autoplay,
                  EffectFade
                ]}
                slidesPerView={1}
                loop
                effect="fade"
                speed={500}
                fadeEffect={{
                  crossFade: true
                }}
                className="mySwiper"
                autoplay={{
                  delay: 2000
                }}
              >
                {cmsData?.map((item, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <section className="mainbanner">
                        <div className="bannerslider">
                          <div className="banner-item">
                            <div className="bannerimg">
                              <img
                                src={item.mob_image}
                                alt="temple images"
                              ></img>
                            </div>
                          </div>
                        </div>
                      </section>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </SwiperSlide>
          </div>
          {/*--------------------------------------------------------------------- (Mobile) home page slider End --------------------------------------------------------------------- */}

          {/* Slider: Section-1--------------------- */}

          {/* --------------------- 3 temples ( temple , gabbar , shaktipith)  */}
          <SwiperSlide>
            <TemplesSection2 />
          </SwiperSlide>
          {/* 3 temples ( temple , gabbar , shaktipith) ---------------------  */}

          {/* --------------------- parikrama sang:Section-3  */}

          {/* <SwiperSlide className="aboutbg overlay overlay-bhadarvi primary window_height Parikrama-gabber">
            <section className="center">
              <div className="container">
                <div className="about-wrapper">
                  <div className="row">
                    <div className="col-xl-10">
                      <h2 style={{ marginBottom: "50px" }}>
                        ગબ્બર તળેટી સંપૂર્ણ શક્તિપીઠ પરિક્રમા સંઘ સ્થાપના અભિયાન
                      </h2>
                      <div
                        className="btn-group"
                        style={{ marginBottom: "20px" }}
                      >
                        <a href="/shaktipith-sang" className="whitebtn">
                          પરિક્રમા સંઘ નોંધણી
                        </a>
                      </div>
                      <br />
                      <div className="btn-group">
                        <a href="/search-shaktipithSang" className="whitebtn">
                          અગાઉની નોંધણી શોધો
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </SwiperSlide> */}

          {/* library-section  */}

          <SwiperSlide className="aboutbg overlay overlay-bhadarvi primary window_height library-bg">
            <section className="service-section">
              <div className="container">
                <div className="about-wrapper">
                  <div className="row text-center">
                    <div className="col-xl-12">
                      <h2 style={{ fontSize: '2.2rem' }}>શક્તિ દર્શનમ્</h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 d-flex justify-content-center align-items-center rounded-start">
                      <FaChevronLeft
                        className="coverage-swiper-button-prev-1"
                        style={{
                          color: '#fff',
                          fontSize: '30px'
                        }}
                      />
                    </div>
                    <div className="col-10 col-lg-10 p-0">
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        navigation={{
                          nextEl: '.coverage-swiper-button-next-1',
                          prevEl: '.coverage-swiper-button-prev-1'
                        }}
                        modules={[Navigation]}
                        breakpoints={{
                          576: {
                            slidesPerView: 1
                          },
                          992: {
                            slidesPerView: 3
                          }
                        }}
                      >
                        <SwiperSlide>
                          <div className="societybox">
                            <div className="societybox-img">
                              <img
                                src="https://storage.googleapis.com/ambaji-temple.appspot.com/1734502280630_ank-208-december-2024.jpg"
                                alt="Ambaji Temple Banaskatha Gujarat"
                              />
                            </div>
                            <div className="societybox-body">
                              <h5 className="my-3">
                                208 - અંક - અંબાજી - શક્તિ દર્શનમ્
                              </h5>

                              <div className="btn-group mb-2">
                                <a
                                  aria-current="page"
                                  className="orangebtn active"
                                  href="https://storage.googleapis.com/ambaji-temple.appspot.com/1734502280646_ANK-208-SHAKTIDARSHANAM.pdf"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  વધુ વાચો
                                </a>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="societybox">
                            <div className="societybox-img">
                              <img
                                src="https://storage.googleapis.com/ambaji-temple.appspot.com/1721198429475_ank-203.jpg"
                                alt="Ambaji Temple Banaskatha Gujarat"
                              />
                            </div>
                            <div className="societybox-body">
                              <h5 className="my-3">
                                203 - અંક - અંબાજી - શક્તિ દર્શનમ્
                              </h5>

                              <div className="btn-group mb-2">
                                <a
                                  aria-current="page"
                                  className="orangebtn active"
                                  href="https://storage.googleapis.com/ambaji-temple.appspot.com/1721199814253_203-AnkAmbajiall.pdf"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  વધુ વાચો
                                </a>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="societybox">
                            <div className="societybox-img">
                              <img
                                src="https://storage.googleapis.com/ambaji-temple.appspot.com/1721198429470_ank-200.jpg"
                                alt="Ambaji Temple Banaskatha Gujarat"
                              />
                            </div>
                            <div className="societybox-body">
                              <h5 className="my-3">
                                200 - અંક - અંબાજી - શક્તિ દર્શનમ્
                              </h5>

                              <div className="btn-group mb-2">
                                <a
                                  aria-current="page"
                                  className="orangebtn active"
                                  href="https://storage.googleapis.com/ambaji-temple.appspot.com/1715158583559_200-Ank-Ambaji-1to32-cm.pdf"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  વધુ વાચો
                                </a>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="societybox">
                            <div className="societybox-img">
                              <img
                                src="https://storage.googleapis.com/ambaji-temple.appspot.com/1721198429480_ank-199.jpg"
                                alt="Ambaji Temple Banaskatha Gujarat"
                              />
                            </div>
                            <div className="societybox-body">
                              <h5 className="my-3">
                                199 - અંક - અંબાજી - શક્તિ દર્શનમ્
                              </h5>

                              <div className="btn-group mb-2">
                                <a
                                  aria-current="page"
                                  className="orangebtn active"
                                  href="https://storage.googleapis.com/ambaji-temple.appspot.com/1710754045884_199-Ank-Ambaji-ShakatiDarshanam-March-2024_CTOC.pdf"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  વધુ વાચો
                                </a>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                    <div className="col-1 d-flex justify-content-center align-items-center rounded-end">
                      <FaChevronRight
                        className="coverage-swiper-button-next-1"
                        style={{
                          color: '#fff',
                          fontSize: '30px'
                        }}
                      />
                    </div>
                  </div>

                  {/* <div className="col-lg-12">
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={20}
                      autoplay={{
                        delay: 2500,
                        clickable: true
                      }}
                      pagination={{
                        clickable: true
                      }}
                      modules={[Autoplay, Pagination]}
                      className="mySwiper"
                      breakpoints={{
                        0: {
                          slidesPerView: 1
                        },
                        768: {
                          slidesPerView: 2
                        },
                        1024: {
                          slidesPerView: 3
                        }
                      }}
                    >
                       <SwiperSlide>
                        <div className="societybox">
                          <div className="societybox-img">
                            <img
                              src="https://storage.googleapis.com/ambaji-temple.appspot.com/1734502280630_ank-208-december-2024.jpg"
                              alt="Ambaji Temple Banaskatha Gujarat"
                            />
                          </div>
                          <div className="societybox-body">
                            <h5 className="my-3">
                              208 - અંક - અંબાજી - શક્તિ દર્શનમ્
                            </h5>

                            <div className="btn-group mb-2">
                              <a
                                aria-current="page"
                                className="orangebtn active"
                                href="https://storage.googleapis.com/ambaji-temple.appspot.com/1734502280646_ANK-208-SHAKTIDARSHANAM.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                વધુ વાચો
                              </a>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>

                       <SwiperSlide>
                        <div className="societybox">
                          <div className="societybox-img">
                            <img
                              src="https://storage.googleapis.com/ambaji-temple.appspot.com/1721198429475_ank-203.jpg"
                              alt="Ambaji Temple Banaskatha Gujarat"
                            />
                          </div>
                          <div className="societybox-body">
                            <h5 className="my-3">
                              203 - અંક - અંબાજી - શક્તિ દર્શનમ્
                            </h5>

                            <div className="btn-group mb-2">
                              <a
                                aria-current="page"
                                className="orangebtn active"
                                href="https://storage.googleapis.com/ambaji-temple.appspot.com/1721199814253_203-AnkAmbajiall.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                વધુ વાચો
                              </a>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>

                       <SwiperSlide>
                        <div className="societybox">
                          <div className="societybox-img">
                            <img
                              src="https://storage.googleapis.com/ambaji-temple.appspot.com/1721198429470_ank-200.jpg"
                              alt="Ambaji Temple Banaskatha Gujarat"
                            />
                          </div>
                          <div className="societybox-body">
                            <h5 className="my-3">
                              200 - અંક - અંબાજી - શક્તિ દર્શનમ્
                            </h5>

                            <div className="btn-group mb-2">
                              <a
                                aria-current="page"
                                className="orangebtn active"
                                href="https://storage.googleapis.com/ambaji-temple.appspot.com/1715158583559_200-Ank-Ambaji-1to32-cm.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                વધુ વાચો
                              </a>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>

                       <SwiperSlide>
                        <div className="societybox">
                          <div className="societybox-img">
                            <img
                              src="https://storage.googleapis.com/ambaji-temple.appspot.com/1721198429480_ank-199.jpg"
                              alt="Ambaji Temple Banaskatha Gujarat"
                            />
                          </div>
                          <div className="societybox-body">
                            <h5 className="my-3">
                              199 - અંક - અંબાજી - શક્તિ દર્શનમ્
                            </h5>

                            <div className="btn-group mb-2">
                              <a
                                aria-current="page"
                                className="orangebtn active"
                                href="https://storage.googleapis.com/ambaji-temple.appspot.com/1710754045884_199-Ank-Ambaji-ShakatiDarshanam-March-2024_CTOC.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                વધુ વાચો
                              </a>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div> */}
                </div>
              </div>
            </section>
          </SwiperSlide>
          {/* parikrama sang:Section-3 ---------------------  */}

          {/* --------------------- mandir schedule:Section-4  */}

          <SwiperSlide>
            <section className="schedule-section service-section">
              <div className="container">
                <div className="row">
                  {scheduleData?.map((item, i) => {
                    return (
                      <div className="col-md-12 col-lg-4" key={i}>
                        <h2>
                          <span>સમય અનુસૂચિ</span>
                        </h2>
                        <div className="todaydate">
                          <div className="templeimg">
                            <img
                              src={TempleIcon}
                              style={{ width: 'auto', height: 'auto' }}
                            ></img>
                          </div>
                          <div>
                            <div className="date">{currentDate}</div>
                            <p className="ps-3">
                              {tithi?.map((datatithi, i) => {
                                if (tithi.length === 1) {
                                  return <>{datatithi?.tithi}</>;
                                }
                                if (tithi.length - 1 === i) {
                                  return <>{datatithi?.tithi}</>;
                                } else {
                                  return <>{datatithi?.tithi},</>;
                                }
                              })}
                            </p>
                            {/* <p className="ps-3">{tithi?.tithi}</p> */}
                          </div>
                        </div>
                        <div className="eventbox">
                          <div className="event-timeicon">
                            <img
                              src={SunriseRed}
                              alt="Ambaji Temple Banaskatha Gujarat"
                            ></img>
                            <h5>સવાર </h5>
                          </div>
                          {item?.morning?.map((data, index) => {
                            return (
                              <>
                                <p key={index}>
                                  <b>{data.key}:</b> {data.start_time} to{' '}
                                  {data.end_time}
                                </p>
                              </>
                            );
                          })}
                        </div>
                        <div className="eventbox">
                          <div className="event-timeicon">
                            <img
                              src={AftrnoonRed}
                              alt="Ambaji Temple Banaskatha Gujarat"
                            ></img>
                            <h5>બપોર</h5>
                          </div>
                          {item?.afternoon?.map((data) => {
                            return (
                              <>
                                <p key={data.key}>
                                  <b>{data.key}: </b> {data.start_time} to{' '}
                                  {data.end_time}
                                </p>
                              </>
                            );
                          })}
                        </div>
                        <div className="eventbox">
                          <div className="event-timeicon">
                            <img
                              src={EveningRed}
                              alt="Ambaji Temple Banaskatha Gujarat"
                            ></img>
                            <h5>સાંજ</h5>
                          </div>
                          {item?.evening?.map((data) => {
                            return (
                              <>
                                <p key={data.key}>
                                  <b>{data.key}: </b> {data.start_time} to{' '}
                                  {data.end_time}
                                </p>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}

                  <div className="col-md-12 col-lg-8">
                    <h2>
                      <span>ઉત્સવો</span>
                    </h2>
                    <div className="prasango">
                      <button
                        className={`button ${
                          selectedUtsav ? 'orangebtn' : 'whitebtn'
                        }`}
                        htmlFor="danger-outlined"
                        style={{
                          margin: '0px 10px 0px 0px'
                        }}
                        onClick={handleUpcomingEventsClick}
                      >
                        આગામી ઉત્સવો
                      </button>
                      <button
                        className={`button ${
                          !selectedUtsav ? 'orangebtn' : 'whitebtn'
                        }`}
                        htmlFor="success-outlined"
                        onClick={handlePreviousEventsClick}
                      >
                        પૂર્ણ થયેલ ઉત્સવો
                      </button>

                      <Swiper
                        direction={'horizontal'}
                        slidesPerView={2}
                        spaceBetween={20}
                        pagination={{
                          clickable: true
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                        breakpoints={{
                          768: {
                            slidesPerView: 2
                          },
                          992: {
                            slidesPerView: 2
                          },
                          1200: {
                            slidesPerView: 2
                          },
                          0: {
                            slidesPerView: 1
                          }
                        }}
                      >
                        {showPreviousEvents && (
                          <div>
                            {pastData?.length > 0 ? (
                              <>
                                {pastData?.map((item, i) => {
                                  return (
                                    <SwiperSlide key={i}>
                                      <div
                                        style={{ margin: '0px 0px 20px' }}
                                        key={item}
                                      >
                                        <div className="col-md-12">
                                          <div className="eventpost">
                                            <div className="eventimg">
                                              <img
                                                src={item?.thumbnail_image}
                                                alt={item?.name}
                                              ></img>
                                            </div>
                                            <div className="eventcontent">
                                              <h3>{item?.name}</h3>
                                              <p>
                                                {new Date(
                                                  item?.start_time
                                                ).toLocaleDateString(
                                                  'en-GB'
                                                )}{' '}
                                                to{' '}
                                                {new Date(
                                                  item?.end_time
                                                ).toLocaleDateString('en-GB')}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  );
                                })}
                              </>
                            ) : (
                              <p className="red-text mt-5 text-center">
                                No Past Events
                              </p>
                            )}
                          </div>
                        )}
                      </Swiper>
                      <Swiper
                        direction={'horizontal'}
                        slidesPerView={2}
                        spaceBetween={20}
                        pagination={{
                          clickable: true
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                        breakpoints={{
                          768: {
                            slidesPerView: 2
                          },
                          992: {
                            slidesPerView: 2
                          },
                          1200: {
                            slidesPerView: 2
                          },
                          0: {
                            slidesPerView: 1
                          }
                        }}
                      >
                        {showUpcomingEvents && (
                          <div>
                            {futureData?.length > 0 ? (
                              <>
                                {futureData?.map((item, i) => {
                                  return (
                                    <SwiperSlide key={i}>
                                      <div style={{ margin: '0px 0px 20px' }}>
                                        <div className="col-md-12">
                                          <div className="eventpost">
                                            <div className="eventimg">
                                              <img
                                                src={item?.thumbnail_image}
                                                alt="Ambaji Temple Banaskatha Gujarat"
                                              ></img>
                                            </div>
                                            <div className="eventcontent">
                                              <h3>{item?.name}</h3>
                                              <p>
                                                {new Date(
                                                  item?.start_time
                                                ).toLocaleDateString(
                                                  'en-GB'
                                                )}{' '}
                                                to{' '}
                                                {new Date(
                                                  item?.end_time
                                                ).toLocaleDateString('en-GB')}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  );
                                })}
                              </>
                            ) : (
                              <p className="red-text mt-5 text-center">
                                No Upcoming Events
                              </p>
                            )}
                          </div>
                        )}
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </SwiperSlide>

          {/*mandir schedule:Section-4  ---------------------  */}

          {/*  ---------------------  e-parikrama AR/VR : Section-5  */}

          <SwiperSlide className="aboutbg overlay overlay-bhadarvi primary parikrama-bg window_height">
            <section className="center">
              <div className="container">
                <div className="about-wrapper">
                  <div className="row">
                    <div className="col-xl-10">
                      <h2>AR/VR હેડસેટ દ્વારા અંબાજી મંદિરની ઇ-પરિક્રમા</h2>
                      <p>
                        અમે મંદિરની એક વીડિયો ટૂર બનાવી છે જે ભક્તોને ઓગમેન્ટેડ
                        રિયાલિટી (એઆર)/ વર્ચ્યુઅલ રિયાલિટી (વીઆર) હેડસેટ પહેરીને
                        મંદિરની ડિજિટલ પરિક્રમા કરવા માટે સુવિધા આપે છે.
                      </p>
                      <div className="btn-group">
                        <a className="whitebtn">ટૂંક સમયમાં આવી રહ્યું છે</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </SwiperSlide>

          {/* e-parikrama AR/VR : Section-5   ---------------------   */}

          <SwiperSlide>
            <section className="service-section">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="service-top">
                      <h2>
                        ભક્તો <span> માટેની સુવિધા</span>
                      </h2>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="footerbox">
                            <div className="footericon">
                              <img
                                src={AvashRed}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h5>આવાસ</h5>
                            <p>
                              સામુદાયિક માધના રૂમમાં વિભાજિત એસી અને નોન-એસી
                              રૂમની સુવિધા ઉપલબ્ધ છે
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="footerbox">
                            <div className="footericon">
                              <img
                                src={MasalaRed}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h5>ભોજનાલય</h5>
                            <p>
                              મા આરાસુરી અંબાના મંદિરે આવતા તમામ ભક્તોને દરરોજ
                              ભોજન પ્રસાદ પીરસવામાં આવે છે
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="footerbox">
                            <div className="footericon">
                              <img
                                src={UddanKhatola}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h5>ઉડનખટોલા</h5>
                            <p>
                              સૌ કોઈ માટે નવો અનુભવ અપાવતી ઉડનખટોલા સેવા હવે
                              અંબાજી ખાતે ઉપલબ્ધ છે
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="footerbox">
                            <div className="footericon">
                              <img
                                src={SpotlightLight}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h5>લાઇટ શો</h5>
                            <p>
                              અંબાજી મંદિર ખાતે મનમોહક લાઇટ એન્ડ સાઉન્ડ શોનો
                              અનુભવ કરો, જે ઇતિહાસ અને આધ્યાત્મિકતા દ્વારા
                              મંત્રમુગ્ધ કરી દેનારી યાત્રા છે
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </SwiperSlide>

          {/* ઓનલાઇન સેવાઓ :  ---------------------    */}
          {/* <SwiperSlide>
            <section className="service-section">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <h2>
                      ઓનલાઇન <span>સેવાઓ</span>
                    </h2>
                    <div className="service-list">
                      <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                          <a href="/mandir-yagna-booking">
                            <div className="onlinebox">
                              <div className="online-icon">
                                <img src={yagnaIcon} alt="Ambaji Temple Banaskatha Gujarat"></img>
                              </div>
                              <h5>મંદિર યજ્ઞ બુકિંગ</h5>
                              <p>
                                અંબાજી મંદિર માં યજ્ઞ બુકિંગ કરવા માટે ની ઓનલાઈન
                                બુકિંગ સુવિધા.
                              </p>
                            </div>
                          </a>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                          <a href="/dhaja-booking">
                            <div className="onlinebox">
                              <div className="online-icon">
                                <img src={dhaja} alt="Ambaji Temple Banaskatha Gujarat"></img>
                              </div>
                              <h5>ધજા બુકિંગ </h5>
                              <p>
                                અંબાજી મંદિર પર ધજા ચડવા માટે ની ઓનલાઈન બુકિંગ
                                સુવિધા
                              </p>
                            </div>
                          </a>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                          <a href="/51-shaktipeeth-parikrama-yagna-booking">
                            <div className="onlinebox">
                              <div className="online-icon">
                                <img src={yagna} alt="Ambaji Temple Banaskatha Gujarat"></img>
                              </div>
                              <h5>૫૧ શક્તિપીઠ યજ્ઞ બુકિંગ</h5>
                              <p>
                                51 શક્તિપીઠ પરિક્રમા મોહોત્સવ દરમિયાન યજ્ઞમાં
                                ભાગ લેવા માટે ની ઓનલાઈન બુકિંગ સુવિધા.
                              </p>
                            </div>
                          </a>
                        </div>
                        <div className="text-center mt-3">
                          <a
                            href="/ambaji-temple-online-booking-services "
                            className="whitebtn"
                          >
                            બધી સેવાઓ જુઓ
                          </a>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                          <a href="/mandir-yagna-booking">
                            <div className="onlinebox">
                              <div className="online-icon">
                                <img src={yagnaIcon} alt="Ambaji Temple Banaskatha Gujarat"></img>
                              </div>
                              <h5>મંદિર યજ્ઞ બુકિંગ</h5>
                              <p>
                                અંબાજી મંદિર માં યજ્ઞ બુકિંગ કરવા માટે ની ઓનલાઈન
                                બુકિંગ સુવિધા.
                              </p>
                            </div>
                          </a>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="onlinebox">
                            <div className="online-icon">
                              <img src={dhaja} alt="Ambaji Temple Banaskatha Gujarat"></img>
                            </div>
                            <h5>ધજા બુકિંગ (ટૂંક સમયમાં આવી રહ્યું છે)</h5>
                            <p>
                              અંબાજી મંદિર પર ધજા ચડવા માટે ની ઓનલાઈન બુકિંગ
                              સુવિધા
                            </p>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="onlinebox">
                            <div className="online-icon">
                              <img src={yagna} alt="Ambaji Temple Banaskatha Gujarat"></img>
                            </div>
                            <h5>
                              ૫૧ શક્તિપીઠ યજ્ઞ બુકિંગ (ટૂંક સમયમાં આવી રહ્યું
                              છે)
                            </h5>
                            <p>
                              51 શક્તિપીઠ પરિક્રમા મોહોત્સવ દરમિયાન યજ્ઞમાં ભાગ
                              લેવા માટે ની ઓનલાઈન બુકિંગ સુવિધા.
                            </p>
                          </div>
                        </div>
                        <div className="text-center mt-3">
                          <a
                            href="/ambaji-temple-online-booking-services "
                            className="whitebtn"
                          >
                            બધી સેવાઓ જુઓ
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </SwiperSlide> */}

          {/* ઓનલાઇન સેવાઓ :  ---------------------    */}

          {/* online services : Section-6  ---------------------    */}

          {/* ---------------------  donation : Section-7  */}

          <SwiperSlide className="society-section overlay primary society-bg window_height">
            <section className="service-section">
              <div className="container">
                <div className="society-wrapper">
                  <div className="text-center"></div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="societybox">
                        <div className="societybox-img">
                          <img
                            src={MoneyDonation}
                            alt="Ambaji Temple Money Donation"
                          />
                        </div>
                        <div className="societybox-body">
                          <h5>પૈસાનું દાન કરો</h5>
                          <p>અંબાજી મંદિરને ઓનલાઈન પૈસા દાન કરો</p>
                          <a href="/donation-money" className="orangebtn">
                            દાન કરો
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="societybox">
                        <div className="societybox-img">
                          <img
                            src="https://storage.googleapis.com/ambaji-temple.appspot.com/1692249623208_SikharDan.png"
                            alt="Ambaji Temple Gold Donation"
                          />
                        </div>
                        <div className="societybox-body">
                          <h5>સોનાનું દાન કરો</h5>
                          <p>અંબાજી મંદિરના સુવર્ણ શિખર ના હેતુ માટે દાન કરો</p>
                          <a href="/donation-gold" className="orangebtn">
                            દાન કરો
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </SwiperSlide>

          {/* donation : Section-7 ---------------------   */}

          {/* ---------------------  ambaji prasad : Section-9 */}

          <SwiperSlide className="prashad-section window-height">
            <section className="center">
              <div className="container">
                <div className="prashad-wrapper">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="prashadcontent">
                        <div className="serviceicon">
                          <img
                            src={RedTemple}
                            style={{ filter: 'none' }}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                        </div>
                        <h2>
                          <span>અંબાજી પ્રસાદ</span> તમારા ઘર આંગણે
                        </h2>
                        <p>
                          અંબાજી મંદિરનો પ્રસાદ ઓનલાઇન ઓર્ડર કરી તમારા ઘરે
                          ડિલિવરી મેળવો
                        </p>
                        {/*=========e-commerce-button=======*/}
                        <button
                          className="orangebtn"
                          onClick={() => navigate(routes?.onlinePrasad)}
                        >
                          ઓનલાઇન ખરીદી કરો
                        </button>
                        {/*=========e-commerce-button=======*/}
                        {/* {ComingSoonComponent} */}
                      </div>
                    </div>
                    <div className="col-md-6 text-center">
                      <div className="prashadimg">
                        <img
                          src={prasad}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </SwiperSlide>

          {/* ambaji prasad : Section-9  ---------------------   */}

          {/* ---------------------  shopping : Section-8  */}

          {/* <SwiperSlide>
            <section className="shop-section service-section">
              <div className="container">
                <div className="text-center">
                  <h2>
                    <span>ઓનલાઇન ખરીદી</span>
                  </h2>
                </div>
                <div className="row">
                  <div className="col-1 d-flex justify-content-center align-items-center rounded-start">
                    <FaChevronLeft
                      className="coverage-swiper-button-prev"
                      style={{
                        color: '#ae0015',
                        fontSize: '30px'
                      }}
                    />
                  </div>
                  <div className="col-10 col-lg-10 p-0">
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={10}
                      navigation={{
                        nextEl: '.coverage-swiper-button-next',
                        prevEl: '.coverage-swiper-button-prev'
                      }}
                      loop={true}
                      modules={[Navigation]}
                      breakpoints={{
                        576: {
                          slidesPerView: 1
                        },
                        992: {
                          slidesPerView: 2
                        }
                      }}
                    >
                      {productData &&
                        productData.map((item, i) => (
                          <SwiperSlide key={item.id}>
                            <div className="societybox">
                              <div className="societybox-img societybox-img-shop">
                                <img
                                  src={item.thumbnail_image}
                                  alt={item.name}
                                />
                              </div>
                              <div className="societybox-body">
                                <h5 className="heading-textes">{item.name}</h5>
                                <p>{item?.description?.substring(0, 60)}...</p>
                                <div className="button-row">
                                  <button
                                    className="orangebtn"
                                    onClick={() =>
                                      navigate(
                                        `/online-shopping-detail/${item?.name}/${item.id}`,
                                        {
                                          state: { id: item?.id }
                                        }
                                      )
                                    }
                                  >
                                    Buy Now
                                  </button>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                  <div className="col-1 d-flex justify-content-center align-items-center rounded-end">
                    <FaChevronRight
                      className="coverage-swiper-button-next"
                      style={{
                        color: '#ae0015',
                        fontSize: '30px'
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>
          </SwiperSlide> */}

          {/* shopping : Section-8 ---------------------   */}

          {/* blogs  */}

          {/* <SwiperSlide>
              <section className="blog-section center">
                <div className="container">
                  <div className="max-heighest">
                    <div className="text-center blog-heading">
                      <h2>બ્લોગ્સ</h2>
                    </div>
                    <div className="blog-wrapper">
                      <div className="row">
                        <div className="col-1 d-flex justify-content-center align-items-center rounded-start">
                          <FaChevronLeft
                            className="coverage-swiper-button-prev"
                            style={{
                              color: "#ae0015",
                              fontSize: "30px",
                            }}
                          />
                        </div>
                        <div className="col-10 col-lg-10 p-0">
                          <Swiper
                            slidesPerView={3}
                            spaceBetween={20}
                            navigation={{
                              nextEl: ".coverage-swiper-button-next",
                              prevEl: ".coverage-swiper-button-prev",
                            }}
                            loop={true}
                            modules={[Navigation]}
                            breakpoints={{
                              576: {
                                slidesPerView: 2,
                              },
                              992: {
                                slidesPerView: 3,
                              },
                            }}
                          >
                            {blogData?.map((item, i) => {
                              return (
                                <SwiperSlide key={i}>
                                  <div className="blogbox">
                                    <div className="blogbox-img">
                                      <img
                                        src={item.thumbnail_image}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </div>
                                    <div className="blogbox-body">
                                      <h5
                                        className="mb-3"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {item?.name?.substring(0, 20)}...
                                      </h5>
                                      <p>
                                        {item?.description?.substring(0, 50)}...
                                      </p>
                                      <button
                                        type="button"
                                        className="orangebtn"
                                        onClick={() =>
                                          navigate(routes?.blogDetail, {
                                            state: { id: item?.id },
                                          })
                                        }
                                      >
                                        View
                                      </button>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                          
                        </div>
                        <div className="col-1 d-flex justify-content-center align-items-center rounded-end">
                          <FaChevronRight
                            className="coverage-swiper-button-next"
                            style={{
                              color: "#ae0015",
                              fontSize: "30px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </section>
            </SwiperSlide> */}

          {/*---------------------  how to reach : Section-10 */}

          <SwiperSlide className="reach-section overlay primary reach-bg window_height">
            <section className="service-section">
              <div className="container">
                <div className="reach-wrapper">
                  <h2>
                    અંબાજી <span>કેવી રીતે પહોંચવું</span>
                  </h2>
                  <div className="reachrow">
                    <div className="reachbox">
                      <div className="reach-icon">
                        <i className="iicon-icon-all">
                          <img
                            src={carBlack}
                            alt="car-black"
                            className="img-bottom"
                          ></img>
                          <img
                            src={carWhite}
                            alt="car-white"
                            className="img-top"
                          ></img>
                        </i>
                      </div>
                      <h5>રોડ માર્ગે અંબાજી પહોંચવું</h5>
                      <p>
                        માઉન્ટ આબુ (50 કિમી), ઉદયપુર (172 કિમી), અમદાવાદ (175
                        કિમી), દ્વારકા (620 કિમી), ગીર (520 કિમી) અને પાલનપુર
                        (60 કિમી) જેવા ઘણા મોટા શહેરો સાથે અંબાજી રોડ મારફતે
                        સારી કનેક્ટિવિટી ધરાવે છે. આ સ્થળોએ બસ સેવાના ઘણા
                        વિકલ્પો ઉપલબ્ધ છે. અંબાજી સાથે સારી કનેક્ટિવિટી ધરાવતા
                        અન્ય સ્થળો જોધપુર, સુરત, અંકલેશ્વર, વડોદરા વગેરે છે.
                      </p>
                    </div>
                    <div className="reachbox">
                      <div className="reach-icon">
                        <i className="iicon-icon-all">
                          <img
                            src={planeBlack}
                            alt="plane-black"
                            className="img-bottom"
                          ></img>
                          <img
                            src={planeWhite}
                            alt="plane-white"
                            className="img-top"
                            style={{ top: '1px' }}
                          ></img>
                        </i>
                      </div>
                      <h5>હવાઈ માર્ગે અંબાજી પહોંચવું</h5>
                      <p>
                        અંબાજીનું સૌથી નજીકનું એરપોર્ટ સરદાર વલ્લભાઈ પટેલ
                        ઈન્ટરનેશનલ એરપોર્ટ છે જે અંબાજીથી 180 કિમી દૂર આવેલું
                        છે. આ એરપોર્ટ અંબાજી નગરને વિશ્વના ઘણા મોટા શહેરો અને
                        નગરો સાથે જોડે છે. અંબાજી જવા માટે એરપોર્ટ પરથી ટેક્સી
                        ભાડે લેવી શક્ય છે.
                      </p>
                    </div>
                    <div className="reachbox">
                      <div className="reach-icon">
                        <i className="iicon-icon-all">
                          <img
                            src={trainBlack}
                            alt="train-black"
                            className="img-bottom"
                          ></img>
                          <img
                            src={trainWhite}
                            alt="train-white"
                            className="img-top"
                          ></img>
                        </i>
                      </div>
                      <h5>ટ્રેન દ્વારા અંબાજી પહોંચવું</h5>
                      <p>
                        અંબાજી ગુજરાત અને રાજસ્થાન બંનેની લોકલ ટ્રેનો દ્વારા ખૂબ
                        જ સારી રીતે જોડાયેલું છે કારણ કે તે બંને રાજ્યો સાથે
                        સરહદ ધરાવે છે. આબુ રોડ સ્ટેશન અંબાજીનું સૌથી નજીકનું
                        રેલ્વે સ્ટેશન છે અને તે 20 કિમીના અંતરે છે. આ રેલ્વે
                        સ્ટેશન સૌથી મહત્વપૂર્ણ નગરો અને શહેરો સાથે સારી
                        કનેક્ટિવિટી ધરાવે છે. આ સ્ટેશનથી અંબાજી સુધી ખૂબ જ ઓછા
                        ખર્ચે ટેક્સીઓ અને વાહનવ્યવહારની અન્ય પદ્ધતિઓ ઉપલબ્ધ છે.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </SwiperSlide>

          {/*how to reach : Section-10 ---------------------   */}

          {/*---------------------   arasuri : Section-11  */}

          <SwiperSlide className="window_height">
            <section className="combined-section">
              <div className="container">
                <div className="temple-wrapper" style={{ paddingTop: '5%' }}>
                  <div className="temple-image">
                    <img
                      src={ambajitemple1}
                      alt="Ambaji Temple Banaskatha Gujarat"
                    ></img>
                    <div className="headingdiv">
                      1200 <span>વર્ષનું અસ્તિત્વ</span>
                    </div>
                    <div className="templeicon">
                      <img
                        src={temple1}
                        alt="Ambaji Temple Banaskatha Gujarat"
                      ></img>
                    </div>
                  </div>
                  <div className="temple-content">
                    <h2>
                      આરાસુરી <span>અંબાજી મંદિર</span>
                    </h2>
                    <div className="praydiv">
                      <img
                        src={PrayIcon}
                        alt="Ambaji Temple Banaskatha Gujarat"
                      ></img>
                      દર વર્ષે 12.5M+ ભક્તો
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="FooterHight">
              <Footer />
            </section>
          </SwiperSlide>

          {/*-------------------------- (Mobile) home page ---------------------------------------------------------------------- */}

          {/* <MobileHome /> */}
        </>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};
export default Home;
