import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import Footer from '../../../Components/footer';
import LazyLoader from '../../../Components/lazyLoader';
import { routes } from '../../../_Constants/routes';
import temple1 from '../../../Assets/Images/Temple/temple1.png';
import ambajitemple from '../../../Assets/Images/Home_page/linear.png';
import PrayIcon from '../../../Assets/Images/Home_page/PrayIcon.svg';
import './onlineShopping.scss';
import OnlineShopService from '../../../Api_config/E_store/onlineShop';
import Arasuri from '../../../Components/arasuri';
import CartService from '../../../Api_config/E_store/cart';
import { toast } from 'react-toastify';

const OnlineShopping = () => {
  const navigate = useNavigate();
  const [productData, setProductData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [currentVariant, setCurrentVariant] = useState();
  const [number, setNumber] = useState(1);
  const [id, setId] = useState();

  useEffect(() => {
    OnlineShopService.getShopAll()
      .then((res) => {
        // console.log(res.data);
        setProductData(res.data.data);
        setTimeout(() => {
          setLoading(false);
        }, [5000]);
      })
      .catch((error) => {
        // console.log(error);
        navigate(routes?.error500);
      });
  }, []);

  // GET PRODUCT BY ID
  useEffect(() => {
    if (id) {
      setLoading(true); // Set loading to true when starting to fetch data

      OnlineShopService.getById(id)
        .then((res) => {
          if (res.data.success) {
            setData(res.data.data[0]);
            setCurrentVariant(res.data.data[0]?.ProductsVariants[0]);
            setTimeout(() => {
              setLoading(false);
            }, [5000]);
          }
        })
        .catch((e) => {
          // console.log(e);
          navigate(routes?.error500);
        })
        .finally(() => {
          setLoading(false); // Always set loading to false after API call completes
        });
    }
  }, [id]);

  const addToCart = (productId, variantId) => {
    const userId = parseInt(localStorage.getItem('user_id'));
    localStorage.setItem('previous_url', '/online-shopping-detail');

    if (userId) {
      const requestData = {
        product_id: productId,
        product_variants_id: 2,
        quantity: number,
        user_id: userId
      };
      localStorage.setItem('cartData', JSON.stringify(requestData));

      CartService.addToCartShopping(requestData)
        .then((response) => {
          if (response.data.success) {
            toast.success('Product Added to cart');
          } else {
            console.error('Failed to add to cart:', response.data.message);
            toast.error('Failed to add to cart. Please try again.');
          }
        })
        .catch((error) => {
          console.error('Failed to add to cart:', error);
          toast.error('An error occurred. Please try again.');
        });
    } else {
      toast.info('Please log in to add items to the cart.');
      navigate(routes.login);
    }
  };

  return (
    <>
      <Helmet>
        <title>Buy Ambaji Temple Merchandise Online</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content=" Buy Ambaji Temples Incense Burners, Notebook, Four Vedas, Calendar "
        />
        <meta
          name="keywords"
          content="Buy Ambaji Temples Incense Burners , Buy Ambaji Temple Calendar , Buy Ambaji Temple Notebook, Buy Ambaji Temple Four Vedas "
        />
        <meta name="author" content="BinTech Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!isLoading ? (
        <div className="main-wrapper">
          <section className="header-banner shopOnline-banner">
            <div className="container">
              <h1>ઓનલાઇન ખરીદી કરો</h1>
            </div>
          </section>
          <section className="shop-page">
            <div className="container">
              <div className="row">
                {productData?.map((item) => (
                  <div className="col-md-6 col-lg-6" key={item.id}>
                    <Link
                      to={{
                        pathname: `/online-shopping-detail/${item?.name}/${item.id}`,
                        state: { id: item?.id }
                      }}
                      style={{ textDecoration: 'none', color: 'inherit' }} // Optional styling to remove underline and use default text color
                    >
                      <div className="societybox">
                        <div className="societybox-img societybox-img-shop">
                          <img
                            src={item.thumbnail_image}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          />
                        </div>
                        <div className="societybox-body">
                          {/* <h5>{item.name}</h5> */}
                          <h5
                            className="shopping-heading"
                            style={{ marginTop: '0' }}
                          >
                            {item.name}
                          </h5>
                          <p>
                            {item?.description?.length > 75
                              ? item?.description.substr(0, 75) + '...'
                              : item?.description}
                          </p>
                          <div className="button-row">
                            <button
                              className="orangebtn"
                              onClick={() =>
                                navigate(
                                  `/online-shopping-detail/${item?.name}/${item.id}`,
                                  {
                                    state: { id: item?.id }
                                  }
                                )
                              }
                              // onClick={(e) => e.preventDefault()} // Prevent button from triggering navigation
                            >
                              Buy Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default OnlineShopping;
